import axios from "axios"

const request = axios.create({
    // baseURL:'http://localhost:14725',
    // baseURL:'http://192.168.3.5:8081',
    // baseURL:'http://192.168.3.45:8081',
    // baseURL:'http://192.168.3.16:8081',
    baseURL:'http://182.44.0.53:14725',
    timeout:10000
})

//拦截器
//可以自请求发送前对请求做一些处理
//比如统一token，对请求参数统一加密
request.interceptors.request.use(config=> {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
     config.headers['guantong'] =  JSON.parse(localStorage.getItem('token'));
    return config
}, error => {
    return Promise.reject(error)
});

request.interceptors.response.use(
    response=>{
        let res = response.data;
        if (typeof  res === 'string'){
            res = res ? JSON.parse(res):res
        }
        // const user = Cookies.get('user')
        // if (!user){
        //     router.push('/login')
        // }
        return res;
    },

    error => {
        console.log('err'+error)
        return Promise.reject(error)
    }
)
export default request