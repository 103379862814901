// 防抖
export const debounce = (fn, delay) => {
    var time = null
    return function() {
        let context = this;//记录一下this指向
        let args = arguments;
        //清除定时任务
        if (time) clearTimeout(time);
        time = setTimeout(function() {
            time = null;
            fn.apply(context, args)
        }, delay)
    }
}
// ps:！！！！！！！！防抖扩展---第三个参数控制第一次点击是否立即执行，
// export const debounce = (fn, delay, immediate) => {
// 	var time = null
// 	return function() {
// 		let context = this;//记录一下this指向
// 		let args = arguments;
// 		//清除定时任务
// 		if (time) clearTimeout(time);
// 		// 第一次点击是否立即执行
// 		if (immediate) {
// 			fn.apply(context, args)
// 			immediate = false;

// 			// 超过delay时间immediate重置为true
// 			time = setTimeout(function() {
// 				time = null;
// 				immediate = true;
// 			}, delay)
// 		} else {
// 			time = setTimeout(function() {
// 				time = null;
// 				fn.apply(context, args)
// 			}, delay)
// 		}
// 	}
// }

// 节流
export const throttle=(fn, delay) => {
    // 时间戳
    var timeTwo = 0 //new Date();
    // 定时器
    var timeThree = null;
    return function() {
        let context = this;
        let args = arguments;
        var now = new Date()

        // !!!!时间戳实现【new Date()虽然获取结果不是时间戳但是计算结果会自动转化为时间戳】
        // if(now-timeTwo>=delay){
        //     fn.apply(context,args);
        //     timeTwo=new Date();
        // }

        // !!!!定时器实现
        // if (!timeThree) {
        //     timeThree = setTimeout(function () {
        //         fn.apply(context, args);
        //         timeThree=null;
        //     }, delay)
        // }

        // 结合 ps:最后一次触发在固定频率内会在延迟后触发
        var wait = delay - (now - timeTwo)
        clearTimeout(timeThree)
        if (wait <= 0) {
            fn.apply(context, args);
            timeTwo = new Date();
        } else {
            timeThree = setTimeout(function() {
                fn.apply(context, args);
            }, delay)
        }
    }
}
