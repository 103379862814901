import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "@/views/Login.vue";
import Loginadd from "@/views/Loginadd.vue";

import HoMe from "../views/HoMe.vue";
import UserSalesman from "@/views/UserSalesman.vue";
import DataSource from "@/views/DataSource.vue";
import FlOw from "@/views/FlOw.vue";
import GaTher from "@/views/GaTher.vue";
import PersonalCenter from "@/views/PersonalCenter.vue";
import SysTem from "@/views/SysTem.vue";
import XinXi from "@/views/XinXi.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/loginadd',
    name: 'loginadd',
    component: Loginadd
  },
  {
    path: '/home',
    name: 'home',
    component: HoMe
  },
  {
    path: '/Salesman',
    name: 'Salesman',
    component: UserSalesman
  },
  {
    path: '/datesource',
    name: 'datesource',
    component: DataSource
  },
  {
    path: '/flow',
    name: 'flow',
    component: FlOw
  },
  {
    path: '/gather',
    name: 'gather',
    component: GaTher
  },
  {
    path: '/person',
    name: 'person',
    component: PersonalCenter
  },
  {
    path: '/system',
    name: 'system',
    component: SysTem
  },
  {
    path: '/xinxi',
    name: 'xinxi',
    component: XinXi
  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
