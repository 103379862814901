<template>

  <div>
    <el-container>
      <el-header>
        <h3 class="title-text">医药信息流向查询系统</h3>
        <el-dropdown style="float: right;margin-top: -30px">
          <span class="el-dropdown-link" style="color: white;">
            {{ this.name }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item ><div @click="dianji_password">修改密码</div></el-dropdown-item>
            <el-dropdown-item>
              <div @click="logout()">退出</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-container>
        <el-aside width="200px" style="min-height: 880px">
          <el-row>
            <el-col :span="24">
              <el-menu default-active="8" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
                <el-menu-item index="1" @click="$router.push('/home')">
                  <i class="el-icon-s-home"></i>
                  <span slot="title">首页</span>
                </el-menu-item>
                <el-menu-item index="8" @click="$router.push('/xinxi')">
                  <i class="el-icon-menu"></i>
                  <span slot="title">药品管理</span>
                </el-menu-item>
                <el-menu-item index="2" @click="$router.push('/gather')">
                  <i class="el-icon-s-data"></i>
                  <span slot="title">流向查询</span>
                </el-menu-item>
                <el-menu-item index="3" @click="$router.push('/Salesman')">
                  <i class="el-icon-s-custom"></i>
                  <span slot="title">人员管理</span>
                </el-menu-item>
<!--                <el-menu-item index="4" @click="$router.push('/datesource')">-->
<!--                  <i class="el-icon-setting"></i>-->
<!--                  <span slot="title">数据源管理</span>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="5" @click="$router.push('/flow')">-->
<!--                  <i class="el-icon-s-cooperation"></i>-->
<!--                  <span slot="title">采集列表</span>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="6" @click="$router.push('/person')">-->
<!--                  <i class="el-icon-setting"></i>-->
<!--                  <span slot="title">个人中心</span>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="7" @click="$router.push('/system')">-->
<!--                  <i class="el-icon-setting"></i>-->
<!--                  <span slot="title">系统设置</span>-->
<!--                </el-menu-item>-->
              </el-menu>
            </el-col>
          </el-row>

        </el-aside>
        <el-container>
          <!--          中间内容填充开始-->
          <el-main class="main">
            <div class="main-div">
              <div>
                <span class="input" style="float: right">
                  <el-button @click="chongzhi_sousuo" type="primary" icon="el-icon-search" style="margin-right: 50px">重 置</el-button>
                </span>
                <span class="input" style="float: right">
                  <el-button @click="chaxun_all_1" type="primary" icon="el-icon-search" style="margin-right: 20px">搜 索</el-button>
                </span>
                <span class="input" style="float: right">
                  <el-input v-model="input.input_name" placeholder="请输入名称"></el-input>
                </span>

<!--                <div style="width: 450px;margin-left: 90%;">-->
<!--                  <el-button style="float: left;margin-left: 10px;margin-top: 20px" type="primary"-->
<!--                    icon="el-icon-download" @click="download">导出</el-button>-->
<!--                </div>-->
                <el-table :data="tableData_sales" border max-height="650px" style="width: 100%;">

                  <el-table-column prop="id" label="序号" >
                  </el-table-column>
                  <el-table-column prop="name" label="商品名称" >
                  </el-table-column>
                  <el-table-column prop="tm" label="tm码" >
                  </el-table-column>
<!--                  <el-table-column fixed="right" label="操作">-->
<!--                    <template slot-scope="scope">-->
<!--                      <el-button type="text" size="small">查询</el-button>-->
<!--                      <el-button type="text" size="small">编辑</el-button>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
                </el-table>
              </div>
              <!--                  分页开始-->
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pagefenye_mohu.currentchange"
                  :page-sizes="[10,20,50]"
                  :page-size="pagefenye_mohu.pagesize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pagefenye_mohu.total">
              </el-pagination>
              <!--                  分页结束-->
            </div>
          </el-main>
          <!--          中间内容填充结束-->

          <!-- <el-footer>底部内容部分</el-footer> -->
        </el-container>
      </el-container>
    </el-container>
    <!--重置密码-->
    <el-dialog title="修改密码" :visible.sync="xiugaimima"  width="30%">
      <el-form :model="ruleForm_password" :rules="rules_password" ref="ruleForm_password">
        <el-form-item label="原密码"  prop="yuan_password">
          <el-input style="width: 300px;" v-model="ruleForm_password.yuan_password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="xin_password" >
          <el-input style="width: 300px;" v-model="ruleForm_password.xin_password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="chack_password" >
          <el-input style="width: 300px;" v-model="ruleForm_password.chack_password" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm_password('ruleForm_password'),xiugaimima = false">取 消</el-button>
        <el-button type="primary" @click="queren_password('ruleForm_password')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import router from "@/router";

export default {
  created() {
    //拦截没有登录的用户开始
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token){
      router.push('/')
      this.$message.error("您还没有登录，请登录！")
    }
    else {
      if (this.activeTab === 'sales') {
        this.chaxun_all();
      }
    }
    //request.get('/user/all/')//这个的作用是为了拦截没有登录的情况下不可访问
  },

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm_password.chack_password !== '') {
          this.$refs.ruleForm_password.validateField('chack_password');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm_password.xin_password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      xiugaimima:false,//修改密码
      ruleForm_password:{
        yuan_password:'',
        xin_password:'',
        chack_password:''
      },
      from_password:{
        userId:'',
        newPassword:'',
        oldPassword:'',
      },
      rules_password:{
        yuan_password:[
          { required: true, message: '请输入原密码', trigger: 'blur' },
        ],
        xin_password: [
          { required: true,  validator: validatePass, trigger: 'blur' }
        ],
        chack_password: [
          {required: true,  validator: validatePass2, trigger: 'blur' }
        ],
      },


      // 分页开始
      pagefenye_mohu:{
        total:'',
        pagesize:'',
        currentchange:''
      },


      pagefenye:{
        total:'',
        pagesize:'',
        currentchange:''
      },
      // 分页结束
      //这个是用来做为请求头的数据获取的
      token:JSON.parse(localStorage.getItem('token')),
      loginId:JSON.parse(localStorage.getItem('loginId')),
      name:JSON.parse(localStorage.getItem('name')),
      activeTab: 'sales', // 设置默认激活的标签页

      input: {
        input_id: '',
        input_name: ''
      },
      tableData: [],
      tableData_sales: []
    }
  },
  watch: {//该方法跟下面的mounted方法一样，但是如果单用这个方法的话在该页面进行刷新时执行不了
    // 监听activeTab的变化
    'input.input_name'(newTab, oldTab) {
      this.chaxun_all();
    },
  },

  methods: {
    dianji_password(){
      this.xiugaimima = true;
      this.from_password = {};
      this.ruleForm_password = {};
    },
    //修改密码的弹窗——取消
    resetForm_password(formName) {
      this.$refs[formName].resetFields();
    },
    queren_password(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.from_password.userId = this.loginId
          this.from_password.oldPassword = this.ruleForm_password.yuan_password
          this.from_password.newPassword = this.ruleForm_password.xin_password
          request.post('/users/updatepassword',this.from_password).then(res=>{
            if (res.code === 200 ){
              this.$message.success(res.data)
            } else if(res.code === 501){
              this.$message.error(res.message)
            }
            else {
              this.$message.error("修改失败")
            }
          })
          this.xiugaimima = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },


    // 分页方法
    handleSizeChange(newSize) {
      this.pagefenye_mohu.pagesize = newSize;
      this.chaxun_all(); // 重新获取数据
    },
    handleCurrentChange(newPage) {
      this.pagefenye_mohu.currentchange = newPage;
      this.chaxun_all(); // 重新获取数据
    },
    //分页结束

    // 药品信息分页查询
    inbound () {
      request.get('/medicine/page', {
        params: {
          pageNum: this.pagefenye.currentchange,//这里是动态获取前端的
          pageSize: this.pagefenye.pagesize
        },
        timeout: 5000// 设置超时时间为5秒（5000毫秒）
      }).then(res => {
        if ( res.code === 200){
          this.tableData_sales = res.data.records; // 通常我们取 res.data 作为响应体
          this.pagefenye.total = res.data.total
          this.pagefenye.pagesize = res.data.size
          this.pagefenye.currentchange = res.data.current
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      });
    },
    // 退出登录时删除token数据
    logout() {
      // 执行退出逻辑...

      // 删除 'user_token' cookie
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      // 可选：如果你也想删除所有 cookies
      // this.$cookies.keys().forEach(key => this.$cookies.remove(key));

      // 重定向到登录页面或执行其他操作
      this.$router.push('/');
    },


    chaxun_all(){//这个是搜索查询的方法
      request.post('/medicine/search?pageNum='+ this.pagefenye_mohu.currentchange+'&pageSize='+this.pagefenye_mohu.pagesize+'&keyword='+this.input.input_name).then(res => {
        if ( res.code === 200){
          this.tableData_sales = res.data.records; // 通常我们取 res.data 作为响应体
          this.pagefenye_mohu.total = res.data.total
          this.pagefenye_mohu.pagesize = res.data.size
          this.pagefenye_mohu.currentchange = res.data.current
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      });
    },
    chaxun_all_1(){//这个是搜索查询的方法
      request.post('/medicine/search?pageNum='+ 1+'&pageSize='+10+'&keyword='+this.input.input_name).then(res => {
        if ( res.code === 200){
          this.tableData_sales = res.data.records; // 通常我们取 res.data 作为响应体
          this.pagefenye_mohu.total = res.data.total
          this.pagefenye_mohu.pagesize = res.data.size
          this.pagefenye_mohu.currentchange = res.data.current
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      });
    },



    chongzhi_sousuo(){//这个是  重置  搜索查询的方法
      this.input.input_name = '';
      this.pagefenye_mohu.currentchange = 1
      this.pagefenye_mohu.pagesize = 10
      this.chaxun_all();

    },

    download() {
      this.$confirm('请问是否确认下载文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '下载成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消下载'
        });
      });
    }
  }
}
</script>

<style scoped>
.main {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.main-div {
  background-color: white;
  padding: 20px;
}

.input {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>