
<template>
<div>
  <el-container>
    <el-header>
      <h3 class="title-text">医药信息流向查询系统</h3>
      <el-dropdown style="float: right;margin-top: -30px">
              <span class="el-dropdown-link" style="color: white;">
                {{ this.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
        <el-dropdown-menu >
          <el-dropdown-item ><div @click="dianji_password">修改密码</div></el-dropdown-item>
          <el-dropdown-item ><div @click="logout()">退出</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside width="200px" style="min-height: 880px">
        <el-row>
          <el-col :span="24">
            <el-menu
                default-active="3"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose">
              <el-menu-item index="1" @click="$router.push('/home')">
                <i class="el-icon-s-home"></i>
                <span slot="title" >首页</span>
              </el-menu-item>
              <el-menu-item index="8" @click="$router.push('/xinxi')">
                <i class="el-icon-menu"></i>
                <span slot="title">药品管理</span>
              </el-menu-item>
              <el-menu-item index="2" @click="$router.push('/gather')">
                <i class="el-icon-s-data"></i>
                <span slot="title">流向查询</span>
              </el-menu-item>
              <el-menu-item index="3" @click="$router.push('/Salesman')">
                <i class="el-icon-s-custom"></i>
                <span slot="title">人员管理</span>
              </el-menu-item>
<!--              <el-menu-item index="4" @click="$router.push('/datesource')">-->
<!--                <i class="el-icon-setting"></i>-->
<!--                <span slot="title">数据源管理</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="5" @click="$router.push('/flow')">-->
<!--                <i class="el-icon-s-cooperation"></i>-->
<!--                <span slot="title">采集列表</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="6" @click="$router.push('/person')">-->
<!--                <i class="el-icon-setting"></i>-->
<!--                <span slot="title">个人中心</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="7" @click="$router.push('/system')">-->
<!--                <i class="el-icon-setting"></i>-->
<!--                <span slot="title">系统设置</span>-->
<!--              </el-menu-item>-->
            </el-menu>
          </el-col>
        </el-row>

      </el-aside>
      <el-container>
<!--        中间内容填充开始-->
        <el-main>
          <el-tabs type="border-card" v-model="activeTab"  @tab-click="handleTabClick">
            <el-tab-pane label="用户列表" name="user">
              <el-button type="primary" style="float: right;margin-top: 10px"  @click="user_add_tc()">添 加</el-button>
              <el-table
                  :data="tableData_userlist"
                  border
                  style="width: 100%"
                  v-loading="loading">
                <el-table-column
                    type="index"
                    label="序 号"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="username"
                    label="昵 称"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="password"
                    label="密 码">
                </el-table-column>
                <el-table-column
                    prop="age"
                    label="年 龄">
                </el-table-column>
                <el-table-column
                    prop="phoneNumber"
                    label="电话号码">
                </el-table-column>
                <el-table-column
                    prop="roleName"
                    label="用户身份">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.roleId === 1" type="info">{{ scope.row.roleName }}</el-tag>
                    <el-tag v-else-if="scope.row.roleId === 2" type="success">{{ scope.row.roleName }}</el-tag>
                    <el-tag v-else-if="scope.row.roleId === 3" type="danger">{{ scope.row.roleName }}</el-tag>
                    <el-tag v-else type="warning">{{ scope.row.roleName }}</el-tag> <!-- 当不等于1、2或3时显示 -->
                  </template>
                </el-table-column>
                <el-table-column
                    label="权限组"
                    prop="groupName">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.groupName !== null " >{{scope.row.groupName}}</el-tag>
                  </template>

                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操 作"
                    width="300">
                  <template slot-scope="scope">
                    <el-button @click="huoqu(scope.row)" type="primary" size="mini" plain>编 辑</el-button>
<!--                    <el-button @click=" delete_id(scope.row)" type="text" size="small">删除</el-button>-->

                    <el-popconfirm
                        confirm-button-text='确认'
                        cancel-button-text='取消'
                        icon="el-icon-info"
                        icon-color="red"
                        title="您确定要删除该用色吗？"
                        @confirm="delete_user(scope.row)">
                      <el-button slot="reference"   type="warning" size="mini" style="margin-left: 20px"  plain>删 除</el-button>
                    </el-popconfirm>

                    <el-popconfirm
                        confirm-button-text='确认'
                        cancel-button-text='取消'
                        icon="el-icon-info"
                        icon-color="red"
                        title="您确定要重置密码吗？"
                        @confirm="chongzhi_password(scope.row.id)">
                      <el-button slot="reference"   type="warning" size="mini" style="margin-left: 20px"  plain>重置密码</el-button>
                    </el-popconfirm>

                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="角 色" name="js">
              <el-button style="float: right;margin-top: 10px" type="primary" @click="add_role()">添 加</el-button>
              <el-table
                  :data="form_role_list"
                  border
                  style="width: 100%"
                  v-loading="loading">
                <el-table-column
                    type="index"
                    label="序 号"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="roleName"
                    label="昵 称"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="description"
                    label="描 述"
                    width="250">
                </el-table-column>
                <el-table-column
                    prop="permissionNames"
                    label="权 限">
                  <template slot-scope="scope">
                    <el-tag style="margin-left: 10px" v-for=" i in scope.row.permissions" :key=i.id >{{i.name}}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操 作"
                    width="200"
                    height="500">
                  <template slot-scope="scope">
                    <el-button @click="huoqurole(scope.row)" type="primary" size="mini" plain>编 辑</el-button>
                    <el-popconfirm
                        confirm-button-text='确 认'
                        cancel-button-text='取 消'
                        icon="el-icon-info"
                        icon-color="red"
                        title="您确定要删除该用色吗？"
                        @confirm="delete_roleid(scope.row.id)">
                      <el-button slot="reference"   type="warning" size="mini" style="margin-left: 20px"  plain>删 除</el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="权 限 组" name="shangpinzu">
              <el-button type="primary" style="float: right;margin-top: 10px"  @click="tianji_shangpinzu">添 加</el-button>
              <el-table
                  :data="tableData_shangpinzu"
                  border
                  style="width: 100%"
                  v-loading="loading">
                <el-table-column
                    type="index"
                    label="序 号"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="groupName"
                    label="昵 称"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="description"
                    label="描 述"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="dianpu"
                    label="客户店铺">
                  <template slot-scope="scope">
                    <el-tag style="margin-left: 10px;margin-top: 5px;width: auto" v-for=" i in  scope.row.enterpriseNameList.slice(0, 10)" :key=i >{{i}}</el-tag>
                  </template>                                    <!--                    scope.row.goodsNames.slice(0, 10)限制显示多少个-->
                </el-table-column>
                <el-table-column
                    prop="gys"
                    label="供应商">
                  <template slot-scope="scope">
                    <el-tag style="margin-left: 10px;margin-top: 5px;width: auto" v-for=" i in  scope.row.manufacturerNameList.slice(0, 10)" :key=i >{{i}} </el-tag>
                  </template>                                    <!--                    scope.row.goodsNames.slice(0, 10)限制显示多少个-->
                </el-table-column>
                <el-table-column
                    prop="yaopin"
                    label="药品">
                  <template slot-scope="scope">
                    <el-tag style="margin-left: 10px;margin-top: 5px;width: auto" v-for=" i in  scope.row.goodsNameList.slice(0, 10)" :key=i >{{i}}</el-tag>
                  </template>                                    <!--                    scope.row.goodsNames.slice(0, 10)限制显示多少个-->
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操 作"
                    width="200"
                    height="500">
                  <template slot-scope="scope">
                    <el-button @click="huoqushangpinzu(scope.row) " type="primary" size="mini" plain>编 辑</el-button>
                    <el-popconfirm
                        confirm-button-text='确 认'
                        cancel-button-text='取 消'
                        icon="el-icon-info"
                        icon-color="red"
                        title="您确定要删除该权限组吗？"
                        @confirm="delete_shangpinzu(scope.row.groupId)">
                      <el-button slot="reference"   type="warning" size="mini" style="margin-left: 20px"  plain>删 除</el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>


<!--            <el-tab-pane label="赋予查看商品权限" name="xiangxi">-->
<!--              <el-button type="primary" style="float: right;margin-top: 10px"  @click="yaopin_tianjia">添 加</el-button>-->
<!--              <el-table-->
<!--                  :data="tableData_useryaopin"-->
<!--                  border-->
<!--                  style="width: 100%"-->
<!--                  v-loading="loading">-->
<!--                <el-table-column-->
<!--                    type="index"-->
<!--                    label="序 号"-->
<!--                    width="80">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    prop="goodsGroupName"-->
<!--                    label="昵 称"-->
<!--                    width="180">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    prop="description"-->
<!--                    label="描 述"-->
<!--                    width="180">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    prop="quanxian"-->
<!--                    label="详细权限">-->
<!--                  <template slot-scope="scope">-->
<!--                    <el-tag style="margin-left: 10px;margin-top: 5px;width: 200px" v-for=" i in  scope.row.goodsGroupNameList.slice(0, 10)" :key=i >{{i}}</el-tag>-->
<!--                  </template>                                    &lt;!&ndash;                    scope.row.goodsNames.slice(0, 10)限制显示多少个&ndash;&gt;-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    fixed="right"-->
<!--                    label="操 作"-->
<!--                    width="200"-->
<!--                    height="500">-->
<!--                  <template slot-scope="scope">-->
<!--                    <el-button @click="huoquyaopin(scope.row) " type="primary" size="mini" plain>编 辑</el-button>-->
<!--                    <el-popconfirm-->
<!--                        confirm-button-text='确 认'-->
<!--                        cancel-button-text='取 消'-->
<!--                        icon="el-icon-info"-->
<!--                        icon-color="red"-->
<!--                        title="您确定要删除该商品组吗？"-->
<!--                        @confirm="delete_yaopinid(scope.row.goodsGroupName)">-->
<!--                      <el-button slot="reference"   type="warning" size="mini" style="margin-left: 20px"  plain>删 除</el-button>-->
<!--                    </el-popconfirm>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </el-table>-->
<!--            </el-tab-pane>-->
<!--            <el-tab-pane label="赋予查看供应商权限" name="gongyingshang">-->
<!--              <el-button type="primary" style="float: right;margin-top: 10px"  @click=" gys_tianjia ">添 加</el-button>-->
<!--              <el-table-->
<!--                  :data="tableData_usergongyingshang"-->
<!--                  border-->
<!--                  style="width: 100%;"-->
<!--                  v-loading="loading">-->
<!--                <el-table-column-->
<!--                    type="index"-->
<!--                    label="序 号"-->
<!--                    width="50">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    prop="userName"-->
<!--                    label="昵 称"-->
<!--                    width="100">-->
<!--                </el-table-column>-->

<!--                <el-table-column-->
<!--                    prop="quanxian"-->
<!--                    label="详细权限">-->
<!--                  <template slot-scope="scope">-->
<!--                    <el-tag style="margin-left: 10px;margin-top: 5px;width: 400px" v-for=" i in  scope.row.manufacturerNames" :key=i >-->
<!--                      {{ i }}-->
<!--                    </el-tag>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    fixed="right"-->
<!--                    label="操 作"-->
<!--                    width="100">-->
<!--                  <template slot-scope="scope">-->
<!--                    <el-button @click="huoqugongyingshang(scope.row) " type="primary" size="mini" plain>编 辑</el-button>-->

<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </el-table>-->
<!--            </el-tab-pane>-->
<!--            <el-tab-pane label="赋予查看某店铺权限" name="dianpu">-->
<!--              <el-button type="primary" style="float: right;margin-top: 10px"  @click="dianpu_tianji">添 加</el-button>-->
<!--              <el-table-->
<!--                  :data="tableData_userdianpu"-->
<!--                  border-->
<!--                  style="width: 100%;"-->
<!--                  v-loading="loading">-->
<!--                <el-table-column-->
<!--                    type="index"-->
<!--                    label="序 号"-->
<!--                    width="50">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    prop="username"-->
<!--                    label="昵 称"-->
<!--                    width="100">-->
<!--                </el-table-column>-->

<!--                <el-table-column-->
<!--                    prop="quanxian"-->
<!--                    label="详细权限">-->
<!--                  <template slot-scope="scope">-->
<!--                    <el-tag style="margin-left: 10px;margin-top: 5px;width: 400px" v-for=" i in  scope.row.enterpriseNames" :key=i >-->
<!--                      {{ i }}-->
<!--                    </el-tag>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    fixed="right"-->
<!--                    label="操 作"-->
<!--                    width="100">-->
<!--                  <template slot-scope="scope">-->
<!--                    <el-button @click="huoqudianpu(scope.row) " type="primary" size="mini" plain>编 辑</el-button>-->

<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </el-table>-->
<!--            </el-tab-pane>-->

          </el-tabs>
        </el-main>
<!--        中间内容填充结束-->

        <!-- <el-footer>底部内容部分</el-footer> -->
      </el-container>
    </el-container>
  </el-container>
<!--重置密码-->
  <el-dialog title="修改密码" :visible.sync="xiugaimima"  width="30%">
    <el-form :model="ruleForm_password" :rules="rules_password" ref="ruleForm_password">
      <el-form-item label="原密码"  prop="yuan_password">
        <el-input style="width: 300px;" v-model="ruleForm_password.yuan_password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="xin_password" >
        <el-input style="width: 300px;" v-model="ruleForm_password.xin_password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="chack_password" >
        <el-input style="width: 300px;" v-model="ruleForm_password.chack_password" autocomplete="off"></el-input>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm_password('ruleForm_password'),xiugaimima = false">取 消</el-button>
      <el-button type="primary" @click="queren_password('ruleForm_password')">确 定</el-button>
    </div>
  </el-dialog>



  <!--  添加新的商品组的弹窗-->
  <el-dialog title="新权限组" :visible.sync="shangpinzu_add"
             :close-on-click-modal="false"> <!-- 设置点击外部不关闭弹窗 -->
    <el-form :model="list_shangpinzufrom" style="width: 900px">
      <!--      ref="scrollContainer"-->
      <el-form-item label="名 称">
        <el-input  style="width: 300px" v-model="list_shangpinzufrom.groupName"></el-input>
      </el-form-item>
      <el-form-item label="描 述">
        <el-input  style="width: 300px" v-model="list_shangpinzufrom.description"></el-input>
      </el-form-item>
      <el-form-item>
        <el-tabs type="border-card" v-model="activeTab_zu" @tab-click="handleTabClick_zu">

          <el-tab-pane label="药品" name="yaopin_zu">
            <el-input style="width: 200px" v-model="input_yaopin.goodsName" placeholder="请输入药品名称"></el-input>
            <el-button type="primary" plain @click="sousuo_chongfu">搜 索</el-button>
            <el-button type="primary" plain @click="sousuo_chongfu_chongzhi">重 置</el-button>
            <br>
            <el-checkbox v-model="checkAllyaopin" @change="handleCheckAllChange_yaopin">全选</el-checkbox>
            <el-checkbox-group v-model="list_shangpinzufrom.tmList"  @change="handleCheckedManufacturersChange_yaopin" >
              <div @scroll="handleScrolldp"  style="max-height: 300px; overflow-y: auto;">
                <el-checkbox
                    style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                    v-for="item in list_yaopinfrom_id"
                    :key="item.tm"
                    :label="item.tm"
                    :name="item.name">
                  {{ item.name }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-tab-pane>

          <el-tab-pane label="供应商" name="gongyinshang_zu">
            <el-input style="width: 200px" v-model="gongyingshang_mohu" placeholder="请输入供应商名称"></el-input>
            <el-button type="primary" plain @click="list_gongyingshang_mohu">搜 索</el-button>
            <el-button type="primary" plain @click="list_gongyingshang_mohu_chongzhi">重 置</el-button>
            <br>
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange_gsy">全选</el-checkbox>

            <el-checkbox-group v-model="list_shangpinzufrom.manufacturerIdList"  @change="handleCheckedManufacturersChange_gys" >
              <div @scroll="handleScrollgys"  style="max-height: 300px; overflow-y: auto;">
                <el-checkbox
                    style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                    v-for="item in list_gongyingshangfrom_id"
                    :key="item.manufacturerId"
                    :label="item.manufacturerId"
                    :name="item.manufacturerName">
                  {{ item.manufacturerName }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-tab-pane>

          <el-tab-pane label="店铺" name="dianpu_zu">
            <el-input style="width: 200px" v-model="dianpu_mohu" placeholder="请输入店铺名称"></el-input>
            <el-button type="primary" plain @click="list_dianpu_mohu">搜 索</el-button>
            <el-button type="primary" plain @click="list_dianpu_mohu_chongzhi">重 置</el-button>
            <br>
            <el-checkbox v-model="checkAlldianpu" @change="handleCheckAllChange_dianpu">全选</el-checkbox>

            <el-checkbox-group v-model="list_shangpinzufrom.enterpriseIdList"  @change="handleCheckedManufacturersChange_dianpu" >
              <div @scroll="handleScrolldp"  style="max-height: 300px; overflow-y: auto;">
                <el-checkbox
                    style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                    v-for="item in list_dianpufrom_id"
                    :key="item.enterpriseId"
                    :label="item.enterpriseId"
                    :name="item.enterpriseName">
                  {{ item.enterpriseName }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-tab-pane>

        </el-tabs>
      </el-form-item>


    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click=" quxiao_shangpinzu_add() ">取 消</el-button>
      <el-button type="primary" @click=" queren_shangpinzu_add() ">确 定</el-button>
    </div>
  </el-dialog>

  <!--  查询修改  权限组 -->
  <el-dialog title="修改 权限组 权限" :visible.sync="chaxuanshangpinzu" :close-on-click-modal="false">
    <el-form :model="list_shangpinzufrom" style="width: 900px">
      <!--      ref="scrollContainer"-->
      <el-form-item label="名 称">
        <el-input  style="width: 300px" v-model="list_shangpinzufrom.groupName"></el-input>
      </el-form-item>
      <el-form-item label="描 述">
        <el-input  style="width: 300px" v-model="list_shangpinzufrom.description"></el-input>
      </el-form-item>
      <el-form-item>
        <el-tabs type="border-card" v-model="activeTab_zu" @tab-click="handleTabClick_zu">

          <el-tab-pane label="药品" name="yaopin_zu">
            <el-input style="width: 200px" v-model="input_yaopin.goodsName" placeholder="请输入药品名称"></el-input>
            <el-button type="primary" plain @click="sousuo_chongfu">搜 索</el-button>
            <el-button type="primary" plain @click="sousuo_chongfu_chongzhi">重 置</el-button>
            <br>
            <el-checkbox v-model="checkAllyaopin" @change="handleCheckAllChange_yaopin">全选</el-checkbox>
            <el-checkbox-group v-model="list_shangpinzufrom.tmList"  @change="handleCheckedManufacturersChange_yaopin" >
              <div @scroll="handleScrolldp"  style="max-height: 300px; overflow-y: auto;">
                <el-checkbox
                    style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                    v-for="item in list_yaopinfrom_id"
                    :key="item.tm"
                    :label="item.tm"
                    :name="item.name">
                  {{ item.name }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-tab-pane>

          <el-tab-pane label="供应商" name="gongyinshang_zu">
            <el-input style="width: 200px" v-model="gongyingshang_mohu" placeholder="请输入供应商名称"></el-input>
            <el-button type="primary" plain @click="list_gongyingshang_mohu">搜 索</el-button>
            <el-button type="primary" plain @click="list_gongyingshang_mohu_chongzhi">重 置</el-button>
            <br>
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange_gsy">全选</el-checkbox>

            <el-checkbox-group v-model="list_shangpinzufrom.manufacturerIdList"  @change="handleCheckedManufacturersChange_gys" >
              <div @scroll="handleScrollgys"  style="max-height: 300px; overflow-y: auto;">
                <el-checkbox
                    style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                    v-for="item in list_gongyingshangfrom_id"
                    :key="item.manufacturerId"
                    :label="item.manufacturerId"
                    :name="item.manufacturerName">
                  {{ item.manufacturerName }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-tab-pane>

          <el-tab-pane label="店铺" name="dianpu_zu">
            <el-input style="width: 200px" v-model="dianpu_mohu" placeholder="请输入店铺名称"></el-input>
            <el-button type="primary" plain @click="list_dianpu_mohu">搜 索</el-button>
            <el-button type="primary" plain @click="list_dianpu_mohu_chongzhi">重 置</el-button>
            <br>
            <el-checkbox v-model="checkAlldianpu" @change="handleCheckAllChange_dianpu">全选</el-checkbox>

            <el-checkbox-group v-model="list_shangpinzufrom.enterpriseIdList"  @change="handleCheckedManufacturersChange_dianpu" >
              <div @scroll="handleScrolldp"  style="max-height: 300px; overflow-y: auto;">
                <el-checkbox
                    style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                    v-for="item in list_dianpufrom_id"
                    :key="item.enterpriseId"
                    :label="item.enterpriseId"
                    :name="item.enterpriseName">
                  {{ item.enterpriseName }}
                </el-checkbox>
              </div>
            </el-checkbox-group>

          </el-tab-pane>
        </el-tabs>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="chaxuanshangpinzu = false ">取 消</el-button>
      <el-button type="primary" @click="addshangpinzu_queren">确 定</el-button>
    </div>
  </el-dialog>


  <!--  查询修改店铺-->
  <el-dialog title="修改店铺权限" :visible.sync="chaxuandianpu" :close-on-click-modal="false">
    <el-form :model="list_dianpufrom" style="width: 900px">
      <!--      ref="scrollContainer"-->
      <el-form-item label="店铺组名称">
        <el-input  style="width: 300px" v-model="list_dianpufrom.dianpuName"></el-input>
      </el-form-item>
      <el-form-item label="店铺权限">
        <br>

        <!--        模糊查询-->
        <el-input style="width: 200px" v-model="dianpu_mohu" placeholder="请输入店铺名称"></el-input>
        <el-button type="primary" plain @click="list_dianpu_mohu">搜 索</el-button>
        <el-button type="primary" plain @click="list_dianpu_mohu_chongzhi">重 置</el-button>
        <br>
        <el-checkbox v-model="checkAlldianpu" @change="handleCheckAllChangedianpu">全选</el-checkbox>

        <el-checkbox-group v-model="list_dianpufrom.permissionIds"  @change="handleCheckedManufacturersChangedianpu" >
          <div @scroll="handleScrolldp"  style="max-height: 300px; overflow-y: auto;">
            <el-checkbox
                style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                v-for="item in list_dianpufrom_id"
                :key="item.enterpriseId"
                :label="item.enterpriseId"
                :name="item.enterpriseName">
              {{ item.enterpriseName }}
            </el-checkbox>
          </div>
        </el-checkbox-group>


      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="chaxuandianpu = false , list_dianpufrom.permissionIds = [] ">取 消</el-button>
      <el-button type="primary" @click="adddianpu_queren">确 定</el-button>
    </div>
  </el-dialog>

  <!--  添加新的店铺组的弹窗-->
  <el-dialog title="新店铺组" :visible.sync="dianpu_add"
             :close-on-click-modal="false"> <!-- 设置点击外部不关闭弹窗 -->
    <el-form :model="list_dianpufrom" style="width: 900px">
      <!--      ref="scrollContainer"-->
      <el-form-item label="店铺组名称">
        <el-input  style="width: 300px" v-model="list_dianpufrom.dianpuName"></el-input>
      </el-form-item>
      <el-form-item label="店铺权限">
        <br>

        <!--        模糊查询-->
        <el-input style="width: 200px" v-model="dianpu_mohu" placeholder="请输入店铺名称"></el-input>
        <el-button type="primary" plain @click="list_dianpu_mohu">搜索</el-button>
        <el-button type="primary" plain @click="list_dianpu_mohu_chongzhi">重 置</el-button>
        <br>
        <el-checkbox v-model="checkAlldianpu" @change="handleCheckAllChangedianpu">全选</el-checkbox>

        <el-checkbox-group v-model="list_dianpufrom.permissionIds"  @change="handleCheckedManufacturersChangedianpu" >
          <div @scroll="handleScrolldp"  style="max-height: 300px; overflow-y: auto;">
            <el-checkbox
                style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                v-for="item in list_dianpufrom_id"
                :key="item.enterpriseId"
                :label="item.enterpriseId"
                :name="item.enterpriseName">
              {{ item.enterpriseName }}
            </el-checkbox>
          </div>
        </el-checkbox-group>


      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click=" quxiao_dianpu_add() ">取 消</el-button>
      <el-button type="primary" @click=" queren_dianpu_add() ">确 定</el-button>
    </div>
  </el-dialog>

<!--  查询修改供应商-->
  <el-dialog title="修改供应商权限" :visible.sync="chaxuangongyingshang" :close-on-click-modal="false">
    <el-form :model="list_gongyingshangfrom" style="width: 900px">
      <el-form-item label="供应商组名称">
        <el-input  style="width: 300px" v-model="list_gongyingshangfrom.gongyingshangName"></el-input>
      </el-form-item>
      <el-form-item label="供应商权限">
        <br>
        <!--        模糊查询-->
        <el-input style="width: 200px" v-model="gongyingshang_mohu" placeholder="请输入供应商名称"></el-input>
        <el-button type="primary" plain @click="list_gongyingshang_mohu">搜索</el-button>
        <el-button type="primary" plain @click="list_gongyingshang_mohu_chongzhi">搜索</el-button>
        <br>
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>

        <el-checkbox-group v-model="list_gongyingshangfrom.permissionIds"  @change="handleCheckedManufacturersChange" >
          <div @scroll="handleScrollgys"  style="max-height: 300px; overflow-y: auto;">
            <el-checkbox
                style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                v-for="item in list_gongyingshangfrom_id"
                :key="item.manufacturerId"
                :label="item.manufacturerId"
                :name="item.manufacturerName">
              {{ item.manufacturerName }}
            </el-checkbox>
          </div>
        </el-checkbox-group>


      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="chaxuangongyingshang = false , list_gongyingshangfrom.permissionIds = [] ">取 消</el-button>
      <el-button type="primary" @click="addgongyingshang_queren">确 定</el-button>
    </div>
  </el-dialog>

  <!--  添加新的供应商组的弹窗-->
  <el-dialog title="新供应商组" :visible.sync="gongyingshang_add"
             :close-on-click-modal="false"> <!-- 设置点击外部不关闭弹窗 -->
    <el-form :model="list_gongyingshangfrom" style="width: 900px">
      <el-form-item label="供应商组名称">
        <el-input  style="width: 300px" v-model="list_gongyingshangfrom.gongyingshangName"></el-input>
      </el-form-item>
      <el-form-item label="供应商权限">
        <br>
        <!--        模糊查询-->
        <el-input style="width: 200px" v-model="gongyingshang_mohu" placeholder="请输入供应商名称"></el-input>
        <el-button type="primary" plain @click="list_gongyingshang_mohu">搜 索</el-button>
        <el-button type="primary" plain @click="list_gongyingshang_mohu_chongzhi">重 置</el-button>
        <br>
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>

        <el-checkbox-group v-model="list_gongyingshangfrom.permissionIds"  @change="handleCheckedManufacturersChange" >
          <div @scroll="handleScrollgys"  style="max-height: 300px; overflow-y: auto;">
            <el-checkbox
                style="width: 390px;height: 70px;float: left; margin-top: 10px;  word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                v-for="item in list_gongyingshangfrom_id"
                :key="item.manufacturerId"
                :label="item.manufacturerId"
                :name="item.manufacturerName">
              {{ item.manufacturerName }}
            </el-checkbox>
          </div>
        </el-checkbox-group>


      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click=" quxiao_gongyingshang_add() ">取 消</el-button>
      <el-button type="primary" @click=" queren_gongyingshang_add() ">确 定</el-button>
    </div>
  </el-dialog>

<!--  设置详细查询药品权限的弹窗-->
  <el-dialog title="修改查询药品权限" :visible.sync="chaxuanyaopin" :close-on-click-modal="false">
    <el-form :model="list_yaopinfrom">
<!--      ref="scrollContainer"-->
      <el-form-item label="药品组名称">
        <el-input  style="width: 300px" v-model="list_yaopinfrom.goodsGroupName"></el-input>
      </el-form-item>
      <el-form-item label="描 述">
        <el-input  style="width: 300px" v-model="list_yaopinfrom.description"></el-input>
      </el-form-item>
      <el-form-item label="药品查询权限">
        <br>
<!--        模糊查询-->
        <el-input style="width: 200px" v-model="input_yaopin.goodsName" placeholder="请输入药品名称"></el-input>
        <el-button type="primary" plain @click="sousuo_chongfu">搜 索</el-button>
        <el-button type="primary" plain @click="sousuo_chongfu_chongzhi">重 置</el-button>

        <br>
        <el-checkbox v-model="checkAllyaopin" @change="handleCheckAllChangeyaopin">全选</el-checkbox>
        <el-checkbox-group v-model="list_yaopinfrom.tmList" @change="handleCheckedManufacturersChangeyaopin" >
<!--          <div @scroll="handleScroll" 加这个是监听的  style="max-height: 300px; overflow-y: auto;">-->
          <div   style="max-height: 300px; overflow-y: auto;">
            <el-checkbox
                style="width: 200px;word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                v-for="item in list_yaopinfrom_id"
                :key="item.tm"
                :label="item.tm"
                :name="item.name">
              {{ item.name }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="chaxuanyaopin = false ,  list_yaopinfrom.tmList = []">取 消</el-button>
      <el-button type="primary" @click="addyaopin_queren">确 定</el-button>
    </div>
  </el-dialog>

  <!--  添加新的药品组的弹窗-->
  <el-dialog title="新药品组" :visible.sync="yaopin_add"
      :close-on-click-modal="false"> <!-- 设置点击外部不关闭弹窗 -->
    <el-form :model="list_yaopinfrom">
      <!--      ref="scrollContainer"-->
      <el-form-item label="药品组名称">
        <el-input  style="width: 300px" v-model="list_yaopinfrom.goodsGroupName"></el-input>
      </el-form-item>
      <el-form-item label="描 述">
        <el-input  style="width: 300px" v-model="list_yaopinfrom.description"></el-input>
      </el-form-item>
      <el-form-item label="药品查询权限">
        <br>
        <!--        模糊查询-->
        <el-input style="width: 200px" v-model="input_yaopin.goodsName" placeholder="请输入药品名称"></el-input>
        <el-button type="primary" plain @click="sousuo_chongfu">搜索</el-button>
        <el-button type="primary" plain @click="sousuo_chongfu_chongzhi">重 置</el-button>

        <br>
        <el-checkbox v-model="checkAllyaopin" @change="handleCheckAllChangeyaopin">全选</el-checkbox>
        <el-checkbox-group v-model="list_yaopinfrom.tmList" @change="handleCheckedManufacturersChangeyaopin" >
          <div @scroll="handleScroll"  style="max-height: 300px; overflow-y: auto;">
            <el-checkbox
                style="width: 200px;word-wrap: break-word;white-space: normal;border: 1px solid #e6e6ed"
                v-for="item in list_yaopinfrom_id"
                :key="item.tm"
                :label="item.tm"
                :name="item.name">
              {{ item.name }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click=" quxiao_yaopin_add() ">取 消</el-button>
      <el-button type="primary" @click=" queren_yaopin_add() ">确 定</el-button>
    </div>
  </el-dialog>


<!--添加用户的表单弹窗-->
  <el-dialog
             width="500px"
             title="添加新用户"
             :visible.sync="dialogFormVisible"
             :close-on-click-modal="false"> <!-- 设置点击外部不关闭弹窗 -->
    <el-form :model="form"  :rules="rules" ref="form" >
      <el-form-item label="姓名" label-width="100px"  prop="username">
        <el-input style="width: 250px" v-model="form.username" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" label-width="100px" prop="password">
        <el-input style="width: 250px" v-model="form.password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="年龄" label-width="100px"   prop="age">
        <el-input style="width: 250px" v-model.number="form.age" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="电话号码" label-width="100px"  prop="phoneNumber">
        <el-input style="width: 250px" v-model="form.phoneNumber" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="用户身份" label-width="100px" prop="selectedRoleId">
        <el-select v-model="selectedRoleId" placeholder="请选择用户身份">
          <el-option
              v-for="item in form_role_list"
              v-if="item.roleName !== '超级管理员'"
              :key="item.id"
              :label="item.roleName"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="商品组" label-width="100px" prop="groupId">
        <el-select v-model="form.groupId" placeholder="请选择商品组">
          <el-option
              v-for="item in tableData_shangpinzu"

              :key="item.groupId"
              :label="item.groupName"
              :value="item.groupId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="user_add_out('form')">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>

  <!--编辑用户信息的弹窗-->
  <el-dialog
      width="500px"
      title="修改信息"
      :visible.sync="updata_userlist"
      :close-on-click-modal="false"> <!-- 设置点击外部不关闭弹窗 -->
    <el-form :model="form_up" >
      <el-form-item label="姓名" label-width="100px" >
        <el-input style="width: 250px" v-model="form_up.username" autocomplete="off"></el-input>
      </el-form-item>
<!--      <el-form-item label="密码" label-width="100px" >-->
<!--        <el-input style="width: 250px" v-model="form_up.password" autocomplete="off"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="年龄" label-width="100px" >
        <el-input style="width: 250px" v-model="form_up.age" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="电话号码" label-width="100px" >
        <el-input style="width: 250px" v-model="form_up.phoneNumber" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="用户身份" label-width="100px">
        <el-select v-model="form_up.roleId" placeholder="请选择用户身份">
          <el-option
              v-for="item in form_role_list"
              v-if="item.roleName !== '超级管理员' && item.roleId !== '3' "
              :key="item.id"
              :label="item.roleName"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品组" label-width="100px">
        <el-select v-model="dainge_zu" placeholder="请选择商品组">
          <el-option
              v-for="item in tableData_shangpinzu"

              :key="item.groupId"
              :label="item.groupName"
              :value="item.groupId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="updata_userlist = false">取 消</el-button>
      <el-button type="primary" @click="user_up()">确 定</el-button>
    </div>
  </el-dialog>

<!--  添加新用户身份的弹窗-->
  <el-dialog
      width="500px"
      title="新用户身份"
      :visible.sync="role_add_from_tanchuang"
      :close-on-click-modal="false"> <!-- 设置点击外部不关闭弹窗 -->
    <el-form :model="role_add_from" >
      <el-form-item label="称号" label-width="100px" >
        <el-input style="width: 250px" v-model="role_add_from.roleName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="描述" label-width="100px" >
        <el-input type="textarea" style="width: 250px" v-model="role_add_from.description" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="管理权限" label-width="100px" >
        <el-checkbox-group  v-model="role_add_from.permissionIds" >
          <el-checkbox v-for="item in role_permission_from" :key="item.id" :label="item.id"  :name="item.name">{{item.name}}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="role_add_qx()">取 消</el-button>
      <el-button type="primary" @click="role_add()">确 定</el-button>
    </div>
  </el-dialog>

<!--  编辑用户身份权限的弹窗-->
  <el-dialog
      title="修改权限"
      :visible.sync="dialogVisible"
      width="500px">
    <!-- 弹窗内容 -->
    <el-form   ref="role_gx_form" :model="role_gx_form" label-width="80px">
      <el-form-item label="身份名称">
        <el-input v-model="role_gx_form.roleName"></el-input>
      </el-form-item>
      <el-form-item label="身份描述">
        <el-input type="textarea" v-model="role_gx_form.description"></el-input>
      </el-form-item>

      <el-form-item label="管理权限"  >
        <el-checkbox-group  v-model="role_gx_form.permissionIds" >
          <el-checkbox v-for="item in role_permission_from" :key="item.id" :label="item.id"  :name="item.name">{{item.name}}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <!-- 弹窗底部操作按钮 -->
    <span slot="footer" class="dialog-footer" >
       <el-button @click="dialogVisible = false">取 消</el-button>
       <el-button type="primary" @click="role_shenfen_gx(), dialogVisible = false">确 定</el-button>
     </span>
  </el-dialog>



</div>
</template>
<script>
import router from "@/router";
import request from "@/utils/request";
import {debounce, throttle} from "@/utils/statice";

export default {
  created() {
    //拦截没有登录的用户开始
    const token = JSON.parse(localStorage.getItem('token'))
    if (!token){
      router.push('/')
      this.$message.error("您还没有登录，请登录！")
    }
    //request.get('/user/all/')//这个的作用是为了拦截没有登录的情况下不可访问
  },

  data(){

    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm_password.chack_password !== '') {
          this.$refs.ruleForm_password.validateField('chack_password');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm_password.xin_password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return{

      xiugaimima:false,//修改密码
      ruleForm_password:{
        yuan_password:'',
        xin_password:'',
        chack_password:''
      },
      from_password:{
        userId:'',
        newPassword:'',
        oldPassword:'',
      },
      rules_password:{
        yuan_password:[
          { required: true, message: '请输入原密码', trigger: 'blur' },
        ],
        xin_password: [
          { required: true,  validator: validatePass, trigger: 'blur' }
        ],
        chack_password: [
          {required: true,  validator: validatePass2, trigger: 'blur' }
        ],
      },


      userGroup_from:[],
      dainge_zu:'',
      add_dainge_zu:'',
      shangpinzu_user:{
        id:'',
        groupId:''
      },



      //商品组
      activeTab_zu :'yaopin_zu',
      tableData_shangpinzu:[],//商品组列表展示

      list_shangpinzufrom_bianji:{},
      list_shangpinzufrom:{
        groupId:'',
        groupName:'',
        description:'',
        enterpriseIdList:[],//客户列表
        tmList:[],//TM码
        manufacturerIdList:[]//供应商
      },//商品组单个的信息对象
      shangpinzu_add:false,//添加商品组的弹窗
      chaxuanshangpinzu:false,//修改商品组的弹窗
      shanchu:{
        groupId:''
      },




      //全选——药品
      checkAllyaopin: false,
      isIndeterminateyaopin: true,

      //全选——供应商
      checkAll: false,
      isIndeterminate: true,

      //全选——供应商
      checkAlldianpu: false,
      isIndeterminatedianpu: true,

      //店铺开始
      tableData_userdianpu:[],//供应商列表
      dianpu_add:false,   //添加店铺组弹窗钩子
      chaxuandianpu:false,//供应商弹窗钩子
      list_dianpufrom:{
        permissionIds:[]
      },
      list_dianpufrom_id:[],//所有供应商列表
      adddianpu:{
        id:'',
        enterpriseIds:[],
      },
      dianpu_mohu:'',
      fanyedp:{
        pageNum: 1,// 当前页码
        pageSize: 100 // 每页数据条数
      },
      //店铺结束

      //供应商开始
      tableData_usergongyingshang:[],//供应商列表
      gongyingshang_add:false,   //添加供应商组弹窗钩子
      chaxuangongyingshang:false,//供应商弹窗钩子
      list_gongyingshangfrom:{
        permissionIds:[]
      },
      list_gongyingshangfrom_id:[],//所有供应商列表
      addgongyingshang:{
        id:'',
        manufacturerIds:[],
      },
      gongyingshang_mohu:'',
      fanyegys:{
        pageNum: 1,// 当前页码
        pageSize: 100 // 每页数据条数
      },
      //供应商结束

      tableData_useryaopin:[],//商品列表
      input_yaopin:{
        goodsName:''
      },

      addyaopin:{
        id:'',
        tm:[],
      },

      list_yaopinfrom:{
        goodsGroupName:'',
        description:'',
        tmList:[]
      },

      isLoading: false, // 是否正在加载数据
      fanye:{
        pageNum: 1,// 当前页码
        pageSize: 100 // 每页数据条数
      },
      list_yaopinfrom_id:[],// 当前展示的数据列表
      chaxuanyaopin: false, //查询药品权限的弹窗钩子
      yaopin_add:false,//添加药品组的弹窗钩子

      token:JSON.parse(localStorage.getItem('token')),
      name:JSON.parse(localStorage.getItem('name')),
      loginId:JSON.parse(localStorage.getItem('loginId')),
      up_id:'',

      qiehuan: true,
      loading: false, // 加载状态变量

      hoverIndex: null, // 用来记录当前鼠标悬停的卡片索引

      dialogVisible: false, // 控制弹窗的显示
      selectedItem: {}, // 存储被点击的卡片项
      role_gx_form:{
        roleName:'',
        description:'',
        permissions:[]
      },
      role_del_id:'',

      tableData_userlist:[],

      activeTab:'user',//默认初始打开的标签页
      form:{
        username:'',
        password:'',
        age:'',
        phoneNumber:'',
        roleId:'',
        roleName:'',
        selectedRoleId:'',
        groupId:'',
        groupName:''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '用户名长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能少于6位', trigger: 'blur' }
        ],
        age: [
          { required: true, message: '请输入年龄', trigger: 'blur' },
          { type: 'number', message: '年龄必须为数字值', trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '电话号码格式不正确', trigger: 'blur' }
        ],
        // selectedRoleId: [
        //   { required: true, message: '请选择用户身份', trigger: 'change' }
        // ]
      },

      role_list_qx:[], //用户身份拥有的权限列表

      role_add_from_tanchuang: false,//添加用户身份的钩子
      role_add_from:{
        roleName:'',
        description:'',
        permissionIds:[]
      },//添加用户身份的表单
      role_permission_from:[],//获取到的身份表单



      //添加用户
      form_role_list:[],//用户身份的查询
      selectedRoleId: null, // 这里存储选中的角色ID
      form_up:{},//编辑信息
      form_up_xin:{
        id:'',
        age:'',
        groupId:'',
        groupName:'',
        phoneNumber:'',
        roleId:'',
        roleName:'',
        username:'',
      },


      dialogFormVisible:false,// 添加用户的弹窗钩子
      updata_userlist: false,// 编辑用户信息的弹窗钩子
      delete_userlist: false,//删除用户的钩子
      delete_userid : {
        id:'',
        username:'',
        password:'',
        age:'',
        phoneNumber:'',
        roleId:''
      },//获取删除用户id


      cz_password:{
        userId:''
      },

    }
  },
  watch: {
    input_yaopin: {
        handler(newVal,oldVal) {
          this.yaopin_mohu();
        },
        deep:true
      },
    gongyingshang_mohu:{
      handler(newVal,oldVal) {
        this.list_gongyingshang();
      },
      deep:true
    },
    dianpu_mohu:{
      handler(newVal,oldVal) {
        this.list_dianpu();
      },
      deep:true

    }

  },


  mounted() {
    this.user_list();
    this.User_shangpinzu();
    // this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);

    // 在组件挂载时添加滚动事件监听器
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {

    //重置密码
    chongzhi_password(res){
      this.cz_password.userId = res
      console.log(res)
      console.log(this.loginId)
      if ( Number(this.loginId) === Number(res) || this.loginId ==='1' ) {
          request.post('/users/resetPassword',this.cz_password).then( rew=>{
            this.$message.success(rew.data)
          })
        } else {
        this.$message.error('您没有权限修改！')
      }
      },
    dianji_password(){
      this.xiugaimima = true;
      this.from_password = {};
      this.ruleForm_password = {};
    },
    //修改密码的弹窗——取消
    resetForm_password(formName) {
      this.$refs[formName].resetFields();
    },
    queren_password(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.from_password.userId = this.loginId
          this.from_password.oldPassword = this.ruleForm_password.yuan_password
          this.from_password.newPassword = this.ruleForm_password.xin_password
          request.post('/users/updatepassword',this.from_password).then(res=>{
            if (res.code === 200 ){
              this.$message.success(res.data)
            } else if(res.code === 501){
              this.$message.error(res.message)
            }
            else {
              this.$message.error("修改失败,原密码错误！")
            }
          })
          this.xiugaimima = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },


    handleTabClick(tab) {
      // 此处可以执行一些基于 tab.name 的逻辑
      // 比如当点击 '入库查询' 标签页时，可能不需要执行任何操作
      // 因为 activeTab 已经自动更新了
      if (this.activeTab === 'user'){
        this.user_list();
        this.User_shangpinzu();
      }
      if (this.activeTab === 'js'){
        this.role_list();
      }
      if (this.activeTab === 'shangpinzu'){
       this. shangpinzu_from();
      }

      if (this.activeTab === 'xiangxi'){
        this.list_yaopin();
        this.role_list_yaopin();
      }
      if (this.activeTab === 'gongyingshang'){
        this.list_gongyingshang();
        this.role_list_gongyingshang();
      }
      if (this.activeTab === 'dianpu'){
        this.list_dianpu();
        this.role_list_dianpu();
      }

    },
    handleTabClick_zu(tab){
      if (this.activeTab_zu === 'yaopin_zu'){
        this.list_yaopin();
      }
      if (this.activeTab_zu === 'gongyinshang_zu'){
        this.list_gongyingshang();
      }
      if (this.activeTab_zu === 'dianpu_zu'){
        this.list_dianpu();
      }
    },

    //全选_药品
    handleCheckAllChange_yaopin(val) {
      this.list_shangpinzufrom.tmList = val ? this.list_yaopinfrom_id.map(item => item.tm) : [];
      this.isIndeterminateyaopin = false;
    },
    handleCheckedManufacturersChange_yaopin(value) {
      let checkedCount = value.length;
      this.checkAllyaopin = checkedCount === this.list_yaopinfrom_id.length;
      this.isIndeterminateyaopin = checkedCount > 0 && checkedCount < this.list_yaopinfrom_id.length;
    },
    //全选_供应商
    handleCheckAllChange_gsy(val) {
      this.list_shangpinzufrom.manufacturerIdList = val ? this.list_gongyingshangfrom_id.map(item => item.manufacturerId) : [];
      this.isIndeterminate = false;
    },
    handleCheckedManufacturersChange_gys(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.list_gongyingshangfrom_id.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.list_gongyingshangfrom_id.length;
    },

    //全选_店铺
    handleCheckAllChange_dianpu(val) {
      this.list_shangpinzufrom.enterpriseIdList = val ? this.list_dianpufrom_id.map(item => item.enterpriseId) : [];
      this.isIndeterminatedianpu = false;
    },
    handleCheckedManufacturersChange_dianpu(value) {
      let checkedCount = value.length;
      this.checkAlldianpu = checkedCount === this.list_dianpufrom_id.length;
      this.isIndeterminatedianpu = checkedCount > 0 && checkedCount < this.list_dianpufrom_id.length;
    },

    //查询用户所分配的商品组
    User_shangpinzu(){
      request.get('/userGroup/list').then(res=>{
        this.userGroup_from = res.data
        console.log(this.userGroup_from)
      })
    },
    user_shangpinzu_fenpei(){
      this.shangpinzu_user.groupId= [];
      this.shangpinzu_user.groupId= this.dainge_zu;
      request.post('/userGroup/update' ,this.shangpinzu_user);
      this.dainge_zu = '';
    },


    //查询所有商品组
    shangpinzu_from(){
      request.get('/group/list').then(res =>{
        this.tableData_shangpinzu = res.data
      })
    },
    tianji_shangpinzu(){
      this.list_shangpinzufrom.groupName = '';
      this.list_shangpinzufrom.description = '';
      this.list_shangpinzufrom.tmList = [];
      this.list_shangpinzufrom.manufacturerIdList = [];
      this.list_shangpinzufrom.enterpriseIdList = [];
      this.shangpinzu_add = true
      this.list_yaopin();
    },

    //确认添加新的商品组
     queren_shangpinzu_add: debounce(function (...args){
      try {
         request.post('/group/add', this.list_shangpinzufrom).then(res =>{
          if ( res.code === 200){
            this.$message.success('添加'+ this.list_shangpinzufrom.groupName + '成功！');
            this.shangpinzu_from();
          }
          this.shangpinzu_add = false;
          this.list_shangpinzufrom.groupName = '';
          this.list_shangpinzufrom.description = '';
          this.list_shangpinzufrom.tmList = [];
          this.list_shangpinzufrom.manufacturerIdList = [];
          this.list_shangpinzufrom.enterpriseIdList = [];
        })
      }catch (error){
        console.error('添加失败:', error);
      }
    },500),
    //取消添加
    quxiao_shangpinzu_add(){
      this.shangpinzu_add = false
      this.list_shangpinzufrom.groupName = '';
      this.list_shangpinzufrom.description = '';
      this.list_shangpinzufrom.tmList = [];
      this.list_shangpinzufrom.manufacturerIdList = [];
      this.list_shangpinzufrom.enterpriseIdList = [];
    },

    //点击编辑
    huoqushangpinzu(row){
      this.chaxuanshangpinzu = true;
      this.list_yaopin();
      this.list_shangpinzufrom = JSON.parse(JSON.stringify(row));

      // this.list_shangpinzufrom.tmList = row.tmList
      // this.list_shangpinzufrom.enterpriseIdList = row.enterpriseIdList
      // this.list_shangpinzufrom.manufacturerIdList = row.manufacturerIdList
    },

    //编辑确认
     addshangpinzu_queren:debounce(function (...args){

      try {
         request.post('/group/update', this.list_shangpinzufrom).then(res =>{
          if (res.code === 200){
            this.$message.success('更新成功！')
            this.shangpinzu_from();
            this.chaxuanshangpinzu = false;
          }
        })
      } catch (error){
        this.$message.error('更新失败！')
      }
    },500),

    //删除商品组
    async delete_shangpinzu(row){
      try {
        this.shanchu.groupId = row
         await request.post('/group/delete?id='+this.shanchu.groupId).then( res=>{
           if (res.code === 200){
             this.$message.success('删除成功！')
             this.shangpinzu_from();
           }
         })
      } catch (error){
        this.$message.error('删除失败！')
      }
    },



///药品部分开始

    //删除商品组
    async delete_yaopinid(row){
      try {
        const res = await request.post('/goodsGroup/delete?goodsGroupName='+ row).then( ()=>{
          this.$message.success('删除成功！')
          this.role_list_yaopin();
        }).error(()=>{
          this.$message.success('删除失败！')
        })
      } catch (error) {
        console.error('删除失败:', error);
      }
    },

    //点击药品添加
    yaopin_tianjia(){
      this.yaopin_add = true
      this.yaopin_mohu();
      this.list_yaopinfrom.tmList = []
      this.list_yaopinfrom.goodsGroupName = ''
      this.list_yaopinfrom.description = ''
    },
    huoquyaopin(res){
      this.chaxuanyaopin = true
      this.addyaopin.id = res.id
      this.input_yaopin.goodsName='';
      this.fanye.pageNum  = 1;
      this.fanye.pageSize = 100;
      // this.list_yaopin();
      this.yaopin_mohu();
      this.list_yaopinfrom.tmList = res.tmList
      // this.$message.success('这个userid是：'+this.addyaopin.id)
    },
    async addyaopin_queren() {//更新用户药品权限
      this.chaxuanyaopin = false;
      this.addyaopin.tm = this.list_yaopinfrom.tmList;

      try {
        const res = await request.post('/goods/update', this.addyaopin);
        this.$message.success('添加成功');
      } catch (error) {
        this.$message.error('添加失败');
        console.error('添加药品失败:', error);
      }
      this.role_list_yaopin();
      this.list_yaopin();
      this.input_yaopin.goodsName='';
      this.list_yaopinfrom.tmList = [];
    },
    list_yaopin: throttle(function (...args){//这里是查询所有药品
      request.post('/goods/list', this.fanye,{
        params: {
          pageNum: this.fanye.pageNum,//这里是动态获取前端的
          pageSize: this.fanye.pageSize
        },
      }).then(res =>{
        if ( res.code === 200){
          this.list_yaopinfrom_id = res.data.records
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      })
    },2000),
    list_yaopin_bianji(){//这里是查询所有药品
      request.post('/goods/list', this.fanye,{
        params: {
          pageNum: 1,//这里是动态获取前端的
          pageSize: 100
        },
      }).then(res =>{
        if ( res.code === 200){
          this.list_yaopinfrom_id = res.data.records
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      })
    },


    //搜索重置
    sousuo_chongfu_chongzhi(){
      this.input_yaopin.goodsName = ''
      this.yaopin_mohu();
    },
    //二次搜索排查监听到底的重复
    sousuo_chongfu() {
      this.yaopin_mohu();
    },
    yaopin_mohu: throttle(function (...args){//药品的模糊查询
      request.post('/goods/select', this.input_yaopin).then(res=>{
        this.list_yaopinfrom_id = res.data
      })
    },2000),



    //全选
    handleCheckAllChangeyaopin(val) {
      this.list_yaopinfrom.tmList = val ? this.list_yaopinfrom_id.map(item => item.tm) : [];
      this.isIndeterminateyaopin = false;
    },
    handleCheckedManufacturersChangeyaopin(value) {
      let checkedCount = value.length;
      this.checkAllyaopin = checkedCount === this.list_yaopinfrom_id.length;
      this.isIndeterminateyaopin = checkedCount > 0 && checkedCount < this.list_yaopinfrom_id.length;
    },

    //药品组的添加的  取消  按钮的操作
    quxiao_yaopin_add(){
      this.yaopin_add = false;
      this.list_yaopinfrom.tmList = [];
    },
    //药品组的添加的  确认  按钮的操作
    async queren_yaopin_add(){
      try {
        const res = await request.post('/goodsGroup/add' , this.list_yaopinfrom).then(res=>{
          if (res.code === 200){
            this.$message.success('添加成功！');
          }
          if (res.code === 400){
            this.$message.error(res.message);
          }
          if( res.code === 500){
            this.$message.error('添加失败！');
          }
        })
      } catch (error) {
        console.error('添加药品失败:', error);
      }
      this.yaopin_add = false;
      this.list_yaopinfrom.tmList = [];
      this.role_list_yaopin();
    },
    //药品部分结束


    // 供应商部分开始

    list_gongyingshang_mohu_chongzhi(){
      this.gongyingshang_mohu = ''
      this.list_gongyingshang();
    },
    list_gongyingshang_mohu(){
      this.fanyegys.pageNum  = 1;
      this.list_gongyingshang();
    },
    //这里接口是查询所有的供应商
    list_gongyingshang:throttle(function (...args){
      request.get('/manufacturer/search', {
        params:{
          pageNum: this.fanyegys.pageNum,//这里是动态获取前端的
          pageSize: this.fanyegys.pageSize,
          keyword:this.gongyingshang_mohu
        }
      }).then(res =>{
        if ( res.code === 200){
          this.list_gongyingshangfrom_id = res.data.records
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      })
    },2000),
    //这里是查询用户所绑定的供应商的列表展示
    role_list_gongyingshang(){
      request.get('/roleManufacturer/lists').then(res=>{
        if ( res.code === 200){
          this.tableData_usergongyingshang = res.data
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      })
    },

    //点击供应商添加
    gys_tianjia(){
      this.list_gongyingshangfrom.permissionIds = []
       this.gongyingshang_add = true
      this.list_gongyingshang();
    },

    //点击编辑时获取到的数据
    huoqugongyingshang(res){
      this.chaxuangongyingshang = true
      this.addgongyingshang.id = res.id
      this.fanyegys.pageNum  = 1
      this.fanyegys.pageSize = 100
      this.gongyingshang_mohu = ''
      this.list_gongyingshang();
      this.list_gongyingshangfrom.permissionIds = res.manufacturerIds
      console.log(this.list_gongyingshangfrom.permissionIds)
      // this.$message.success('这个userid是：'+this.addyaopin.id)
    },
    //在编辑弹窗中点击确定按钮的动作
    async addgongyingshang_queren() {
      this.chaxuangongyingshang = false;
      this.addgongyingshang.manufacturerIds = this.list_gongyingshangfrom.permissionIds;

      try {
        const res = await request.post('/roleManufacturer/update', this.addgongyingshang);
        this.$message.success('添加成功');
      } catch (error) {
        this.$message.error('添加失败');
        console.error('添加药品失败:', error);
      }
      this.role_list_gongyingshang();
      this.list_gongyingshangfrom.permissionIds = [];
    },
    //全选
    handleCheckAllChange(val) {
      this.list_gongyingshangfrom.permissionIds = val ? this.list_gongyingshangfrom_id.map(item => item.manufacturerId) : [];
      this.isIndeterminate = false;
    },
    handleCheckedManufacturersChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.list_gongyingshangfrom_id.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.list_gongyingshangfrom_id.length;
    },
    //供应商组的添加的  取消  按钮的操作
    quxiao_gongyingshang_add(){
      this.gongyingshang_add = false;
      this.list_gongyingshangfrom.permissionIds = [];
    },
    //供应商组的添加的  确认  按钮的操作
    queren_gongyingshang_add(){
      request.post('');
      this.gongyingshang_add = false;
      this.list_gongyingshangfrom.permissionIds = [];
    },

    // 供应商部分结束

    // 店铺部分开始
    list_dianpu_mohu_chongzhi(){
      this.dianpu_mohu = ''
      this.list_dianpu();
    },

    list_dianpu_mohu(){
      this.fanyedp.pageNum  = 1;
      this.list_dianpu();
    },
    //这里接口是查询所有的供应商
    list_dianpu:throttle(function (...args){
      request.get('/enterprise/search', {
        params:{
          pageNum: this.fanyedp.pageNum,//这里是动态获取前端的
          pageSize: this.fanyedp.pageSize,
          keyword:this.dianpu_mohu
        }
      }).then(res =>{
        if ( res.code === 200){
          this.list_dianpufrom_id = res.data.records
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      })
    },2000),
    //这里是查询用户所绑定的供应商的列表展示
    role_list_dianpu(){
      request.get('/enterprise/lists').then(res=>{
        if ( res.code === 200){
          this.tableData_userdianpu = res.data
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      })
    },

    //点击店铺添加
    dianpu_tianji(){
      this.dianpu_add = true
      this.list_dianpufrom.permissionIds = []
      this.list_dianpu();

    },
    //点击编辑时获取到的数据
    huoqudianpu(res){
      this.chaxuandianpu = true
      this.adddianpu.id = res.id
      this.dianpu_mohu = ''
      this.fanyedp.pageNum  = 1
      this.fanyedp.pageSize = 100
      this.list_dianpu();
      this.list_dianpufrom.permissionIds = res.enterpriseIds
      console.log(this.list_dianpufrom.permissionIds)
      // this.$message.success('这个userid是：'+this.addyaopin.id)
    },
    //在编辑弹窗中点击确定按钮的动作
    async adddianpu_queren() {
      this.chaxuandianpu = false;
      this.adddianpu.enterpriseIds = this.list_dianpufrom.permissionIds;

      try {
        const res = await request.post('/enterprise/update', this.adddianpu);
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
        console.error('修改失败:', error);
      }
      this.role_list_dianpu();
      this.list_dianpufrom.permissionIds = [];
    },
    //全选
    handleCheckAllChangedianpu(val) {
      this.list_dianpufrom.permissionIds = val ? this.list_dianpufrom_id.map(item => item.enterpriseId) : [];
      this.isIndeterminatedianpu = false;
    },
    handleCheckedManufacturersChangedianpu(value) {
      let checkedCount = value.length;
      this.checkAlldianpu = checkedCount === this.list_dianpufrom_id.length;
      this.isIndeterminatedianpu = checkedCount > 0 && checkedCount < this.list_dianpufrom_id.length;
    },
    //店铺组的添加的  取消  按钮的操作
    quxiao_dianpu_add(){
      this.dianpu_add = false;
      this.list_dianpufrom.permissionIds = [];
    },
    //店铺组的添加的  确认  按钮的操作
    queren_dianpu_add(){
      request.post('');
      this.dianpu_add = false;
      this.list_dianpufrom.permissionIds = [];
    },

    // 店铺部分结束






    // 处理滚动事件
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        // 滚动条到达底部，触发懒加载
        console.log('到达底部，触发懒加载');
        this.fanye.pageNum ++ ;
        request.post('/goods/list', this.fanye,{
          params: {
            pageNum: this.fanye.pageNum,//这里是动态获取前端的
            pageSize: this.fanye.pageSize
          },
        }).then(res =>{
          if ( res.code === 200){
            // for (let i= 0; i<res.data.records.length; i++ ){//这里的作用时将获取到的数据需要一条一条的拼接到原来的列表中
            //   this.list_yaopinfrom_id.push(res.data.records[i])
            // }
            this.list_yaopinfrom_id = this.list_yaopinfrom_id.concat(res.data.records);//这里的作用时将获取到的数据拼接到原来的列表中
            console.log('程序已重新查询');
          }
          if( res.code === 500){
            if (res.msg[0] === 't'){
              this.$message.error('登录身份已过期，请重新登录!!！');
              this.$router.push('/');
            } else {
              this.$message.error('缺少查看权限!!！');
            }
          }
        })
      }
    },
    handleScrollgys(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        // 滚动条到达底部，触发懒加载
        console.log('到达底部，触发懒加载');
        this.fanyegys.pageNum ++ ;
        request.get('/manufacturer/search',{
          params: {
            pageNum: this.fanyegys.pageNum,//这里是动态获取前端的
            pageSize: this.fanyegys.pageSize,
            keyword: this.gongyingshang_mohu
          },
        }).then(res =>{
          if ( res.code === 200){
            this.list_gongyingshangfrom_id = this.list_gongyingshangfrom_id.concat(res.data.records);//这里的作用时将获取到的数据拼接到原来的列表中
            console.log('程序已重新查询');
          }
          if( res.code === 500){
            if (res.msg[0] === 't'){
              this.$message.error('登录身份已过期，请重新登录!!！');
              this.$router.push('/');
            } else {
              this.$message.error('缺少查看权限!!！');
            }
          }
        })
      }
    },

    handleScrolldp(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        // 滚动条到达底部，触发懒加载
        console.log('到达底部，触发懒加载');
        this.fanyedp.pageNum ++ ;
        request.get('/enterprise/search',{
          params: {
            pageNum: this.fanyedp.pageNum,//这里是动态获取前端的
            pageSize: this.fanyedp.pageSize,
            keyword: this.dianpu_mohu
          },
        }).then(res =>{
          if ( res.code === 200){
            this.list_dianpufrom_id = this.list_dianpufrom_id.concat(res.data.records);//这里的作用时将获取到的数据拼接到原来的列表中
            console.log('程序已重新查询');
          }
          if( res.code === 500){
            if (res.msg[0] === 't'){
              this.$message.error('登录身份已过期，请重新登录!!！');
              this.$router.push('/');
            } else {
              this.$message.error('缺少查看权限!!！');
            }
          }
        })
      }
    },




    //去重
    uniqueListByProperty(propertyName) {
      const uniqueList = [];
      const map = new Map();

      for (const item of this.list_yaopinfrom_id) {
        if (!map.has(item[propertyName])) {
          map.set(item[propertyName], true); // 设置标志表示已存在
          uniqueList.push(item); // 将不重复的项添加到新数组
        }
      }

      this.list_yaopinfrom_id = uniqueList;
    },



    logout() {
      // 执行退出逻辑...
      // 删除 'user_token' cookie
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      // 可选：如果你也想删除所有 cookies
      // this.$cookies.keys().forEach(key => this.$cookies.remove(key));
      // 重定向到登录页面或执行其他操作
      this.$router.push('/');
    },

    //点击添加用户的按钮弹窗
    user_add_tc(){
      this.form={};
      this.selectedRoleId = '';
      this.dialogFormVisible = true ;
      this.role_list(); // 这里作用时查所有的角色
      this.shangpinzu_from();// 这里是查商品组
      this.$refs['form'].resetFields();

    },


    // role_list_yaopin(){
    //   request.get('goods/lists').then(res=>{
    //     if ( res.code === 200){
    //       this.tableData_useryaopin = res.data
    //     }
    //     if( res.code === 500){
    //       if (res.msg[0] === 't'){
    //         this.$message.error('登录身份已过期，请重新登录!!！');
    //         this.$router.push('/');
    //       } else {
    //         this.$message.error('缺少查看权限!!！');
    //       }
    //     }
    //   })
    // },
    role_list_yaopin(){
      request.get('goodsGroup/list').then(res=>{
        if ( res.code === 200){
          this.tableData_useryaopin = res.data
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      })
    },


    //用户身份查询
    role_list(){
      this.loading = true //进行调用接口时加载状态
      request.get('/role/list').then(res=>{
        if ( res.code === 200){
          this.form_role_list = res.data
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      }).finally(()=>{
        this.loading = false//这里表示在接口查询完成时结束
      })
    },
    //添加用户身份的弹窗
    add_role(){
      this.role_add_from_tanchuang = true;
      this.role_permission();
    },

    //用户身份具体查询
    async role_permission(){
      await request.get('/permission/getList', ).then(res=>{
        if ( res.code === 200){
          this.role_permission_from  = res.data
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          }
        }
      })
    },
    //添加新用户身份信息
     role_add:debounce(function (...args){
      try {
         request.post('/role/add',this.role_add_from)
        this.role_add_from.roleName = '';
        this.role_add_from.description = '';
        this.role_add_from.permissionIds = [];
        this.role_add_from_tanchuang = false;
        this.role_list();
        this.$message.success("添加成功！")
      } catch (error){
        console.error("添加角色信息出错!",error);
      }
    }, 500, true),
    //添加新用户信息身份的取消按钮
    role_add_qx(){
      this.$refs[formName].resetFields();
      this.role_add_from_tanchuang = false;// 关闭添加弹窗
      this.role_add_from.roleName = '';
      this.role_add_from.description = '';
      this.role_add_from.permissionIds = [];  //清空信息
    },


    //用户查询
    user_list (){
      this.loading = true//进行调用接口时加载状态
      request.get('/users/select').then(res=>{
        if ( res.code === 200){
          this.tableData_userlist = res.data;
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      }).finally(()=>{
        this.loading = false//这里表示在接口查询完成时结束
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 这里添加提交表单的逻辑
          this.user_add();
          this.$refs[formName].resetFields();

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //用户添加
    user_add(){
      this.form.roleId = this.selectedRoleId;
      request.post('/users/add', this.form
      ).then( () =>{
        this.form={};
        this.user_list();

        this.dialogFormVisible = false;//关闭弹窗

        this.$message.success('添加成功！');

      })
    },
    //添加用户退出
    user_add_out(formName){
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
      this.selectedRoleId = null;
      this.form = {}
    },

    // 获取列表信息
    huoqu(row) {
      this.up_id = row.id
      this.updata_userlist = true;

      this.shangpinzu_user.id = row.id//更新
      this.dainge_zu = row.groupId
      this.role_list();
      this.shangpinzu_from();

      this.form_up = JSON.parse(JSON.stringify(row));//获取表格数据但是不会实时更新表格的内容
    },

    //用户信息的编辑
     user_up: debounce(function (...args){
      try {
        this.form.roleName = '';
        this.form_up_xin.id = this.form_up.id
        this.form_up_xin.age = this.form_up.age
        this.form_up_xin.roleName = this.form_up.roleName
        this.form_up_xin.groupId = this.form_up.groupId
        this.form_up_xin.groupName = this.form_up.groupName
        this.form_up_xin.username = this.form_up.username
        this.form_up_xin.phoneNumber = this.form_up.phoneNumber
        this.form_up_xin.roleId = this.form_up.roleId

        request.post('/users/update',this.form_up_xin).then( res=>{

          if ( res.code === 200){
            this.user_role_up();//这里是用来  修改更新  用户角色的接口
            this.shangpinzu_user.groupId = '';
            this.shangpinzu_user.groupId = this.dainge_zu;
             request.post('/userGroup/update' ,this.shangpinzu_user).then( res=>{
               if (res.code === 200){
                 this.user_list();
                 this.updata_userlist = false;
                 this.$message.success('编辑成功！')
                 this.dainge_zu = '';
               }
            })

          }
        })

        if (Number(this.up_id) === Number(this.loginId)){
          localStorage.setItem('name', JSON.stringify(this.form_up.username));//本地存储存入
          this.name = this.form_up.username
        }
      } catch (error){
      }
    },500, true),

    user_role_up(){
      request.post('/users/updaterole',this.form_up)
    },

    //删除用户
    delete_user: debounce(function(row){
      this.userGroup_from.forEach(item =>{
        if(item.id === row.id){
          this.shangpinzu_user.id = row.id
          this.shangpinzu_user.groupId= item.groupId;
        }
      })
      try {
       request.post('/users/delete',row).then(async rew => {
          if (rew.code === 200){
            request.post('/userGroup/delete', this.shangpinzu_user);
            this.delete_userlist = false;
            this.user_list();
            this.delete_userid = {};
            this.$message.warning('删除成功！')
          } else if (rew.code === 501){
            this.$message.warning(rew.message)
          }

        })

      } catch (error){
       console.log(error);
      }
    }, 500, true),


    //用户身份权限修改
    huoqurole(row){
      this.dialogVisible = true;
      this.role_gx_form = JSON.parse(JSON.stringify(row));//获取表格数据但是不会实时更新表格的内容
      this.role_del_id  =  this.role_gx_form.id
      this.role_permission();
    },


    //如果你的请求库支持 async/await，你可以考虑使用这种语法，它可以让异步代码看起来更像同步代码，也可能更容易理解和维护：
    //这里采用的是async 的同步方法
    async role_shenfen_gx(){
      try{
        await request.post('/role/update',this.role_gx_form);
        await request.post('/role/updatePermissions',this.role_gx_form);
        // 重新获取角色列表以刷新表格
        this.role_list();
      } catch (error){
        console.error("更新角色信息出错!",error);
      }

    },

    async delete_roleid(row){
      try {
        this.$message.success("删除成功！");
        await request.post('/role/delete', row)
        this.dialogVisible = false;
        this.role_list();
      } catch ( error){
        console.error("删除角色信息出错!",error);
      }


    },


  },
  beforeDestroy() {
    if (this.$refs.scrollContainer) {
      this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
    }

    // 在组件销毁前移除滚动事件监听器
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style>
.card-item {
  width: 200px;
  float: left;
  margin-left: 20px;
  margin-top: 20px;
  transition: box-shadow 0.3s ease; /* 平滑过渡阴影效果 */
}
                     /* //cursor: pointer; 将鼠标样式改为指针，表示这是一个可以点击的元素 */
</style>