<template>

  <div>
    <div class="shell">

      <div id="img-box" style="margin-top: 50px;transform: scale(1.1);">
        <img src="../img/bgimg1.png" alt="">
      </div>

      <el-form  :model="loginForm" label-width="100px" :rules="rules" ref="loginForm" style="margin-top: 50px;transform: scale(1.1);">
        <div id="form-body">
          <div id="welcome-lines" style="margin-top: -40px; margin-right: -50px;">
            <!-- <div id="w-line-2">你好! </div> -->
            <div id="w-line-2">你好!  欢迎回来</div>
            <br>
            <br>
          </div>

          <div id="input-area" style="margin-right: 50px; transform: scale(1.5); ">

            <el-form-item label="账号" prop="username" >
              <el-input placeholder="请输入账号" style="width: 200px " type="text" v-model="loginForm.username"
                        size="small"></el-input>
            </el-form-item>
            <el-form-item   type="password" label="密码" prop="password" style="margin-top: 12px">
              <el-input placeholder="请输入密码" style="width: 200px"   v-model="loginForm.password"
                        size="small" @keyup.enter.native="confirm" show-password></el-input>
            </el-form-item>

          </div>
          <div id="submit-button-cvr" style="margin-top: 40px;margin-left: -120%">
            <el-form-item>
              <el-button class="submit-button" type="primary"  @click="throttling('loginForm')" style="width: 200px">登 录</el-button>
            </el-form-item>
          </div>
          <div id="forgot-pass" >
            <el-button type="text" >忘记密码</el-button>
            <el-button type="text" @click="$router.push('/loginadd')">注 册</el-button>
          </div>
        </div>
      </el-form>

    </div>

  </div>







</template>
<script>
import request from "@/utils/request";
import * as Cookies from "vue";
import {debounce, throttle} from "@/utils/statice";


export default {
  name: "MedLogin",
  data() {
    return {
      token:'',
      confirm_disabled: false,
      loginForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
          {min: 1, max: 16, message: '用户名长度在 1 到 16 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输密码', trigger: 'blur'},
          {min: 6, max: 16, message: '密码长度在 6 到 16 个字符', trigger: 'blur'}
        ]
      },
      form:{},
      user_zhuce:false,

    }
  },

  methods: {
    // 事件触发
    //防抖
    antiShake: debounce(function (...args) {
      this.submitForm(...args)
    }, 2000, true),
    //节流
    throttling: throttle(function (...args) {
      this.submitForm(...args)
    }, 2000),

    // 防抖、节流功能函数
    antiShakeBtn(val) {
      //写按钮功能
      console.log(val);
    },
    throttlingBtn(val) {
      //写按钮功能
      console.log(val);
    },





    submitForm(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          request.post('/user/userLogin',this.loginForm).then(res =>{
            if (res.code===200){

              // const cookieName = 'token';
              // const cookieValue = res.data.tokenValue;
              // 设置cookie，假设我们想要设置1天的过期时间
              // this.$cookies.set(cookieName, cookieValue, '1d');
              // this.$cookies.set(cookieName,JSON.stringify(cookieValue))
              // this.$cookies.set('name',JSON.stringify(this.loginForm.username))

              localStorage.setItem('token', JSON.stringify(res.data.tokenValue ));//本地存储存入
              localStorage.setItem('loginId', JSON.stringify(res.data.loginId ));//本地存储存入
              localStorage.setItem('name', JSON.stringify(this.loginForm.username));//本地存储存入
              // // 从 localStorage 获取数据
              // const myData = JSON.parse(localStorage.getItem('myData'));
              // // 如果需要移除数据
              // localStorage.removeItem('myData');

              this.$router.push('/home');//路由跳转
              this.$message.success('欢迎'+this.loginForm.username+"登录系统！");
              console.log('点击一次');

            }else {
              this.$message.error('账号密码错误，请重新输入！');
              console.log('点击一次');
            }
          })
          // alert('submit!');
        } else {
          console.log('error submit!!');
          console.log('点击一次');
          return false;
        }

      });
    },

  }
}
</script>

<style scoped >
* {
  padding: 0;
  margin: 0;
  outline: none;
}
body {
  background: linear-gradient(45deg, #1709e2, #2370ff);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.shell{
  background: linear-gradient(45deg, #5985ff, #2f60ff);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}
form {
  position: relative;
}

.shell {
  display: flex;
  justify-content: center;
}

form {
  width: 560px;
  height: 520px;
  background-color: #fff;
  box-shadow: 0px 15px 40px #81a2d3;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#img-box {
  width: 330px;
  height: 520px;
}

#img-box img {
  height: 100%;
}

#form-body {
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#welcome-lines {
  width: 100%;
  text-align: center;
  line-height: 1;
}

#w-line-1 {
  color: #7f7f7f;
  font-size: 65px;
}

#w-line-2 {
  color: #9c9c9c;
  font-size: 30px;
  margin-top: 17px;
}

#input-area {
  width: 100%;
  margin-top: 40px;
}



.f-inp {
  padding: 13px 25px;
  border: 2px solid #6e6d6d;
  line-height: 1;
  border-radius: 20px;
  margin-bottom: 15px;
}

.f-inp input {
  width: 100%;
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: 0;
}

.f-inp input::placeholder {
  color: #b9b9b9;
}

#submit-button-cvr {
  margin-top: 20px;
}

.submit-button {
  display: block;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-left: 350px;
  padding: 14px 40px;
  border: 0;
  background-color: #81a2d3;
  border-radius: 25px;
  line-height: 1;
  cursor: pointer;
}

#forgot-pass {
  text-align: center;
  margin-top: 10px;
  margin-left: 60px;
}
#forgot-pass a {
  color: #868686;
  font-size: 12px;
  text-decoration: none;
  display: inline-block;
  margin-right: 20px; /*字之间空距离 */
}


</style>