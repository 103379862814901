<template>


<div>
  <el-container>
    <el-header>
      <h3 class="title-text">医药信息流向查询系统</h3>
      <el-dropdown style="float: right;margin-top: -30px">
              <span class="el-dropdown-link" style="color: white;">
                {{this.name}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item ><div @click="dianji_password">修改密码</div></el-dropdown-item>
          <el-dropdown-item ><div @click="logout()">退出</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside width="200px" style="min-height: 880px">
      <el-row>
        <el-col :span="24">
          <el-menu
              default-active="1"
              class="el-menu-vertical-demo"
             >
              <!-- 
                  background-color="#205488"
                  text-color="#fff"
                  active-text-color="#ffd04b" -->
            <el-menu-item index="1" @click="$router.push('/home')">
              <i class="el-icon-s-home"></i>
              <span slot="title" >首页</span>
            </el-menu-item>
            <el-menu-item index="8" @click="$router.push('/xinxi')">
                  <i class="el-icon-menu"></i>
                  <span slot="title">药品管理</span>
                </el-menu-item>
            <el-menu-item index="2" @click="$router.push('/gather')">
              <i class="el-icon-s-data"></i>
              <span slot="title">流向查询</span>
            </el-menu-item>
            <el-menu-item index="3" @click="$router.push('/Salesman')">
              <i class="el-icon-s-custom"></i>
              <span slot="title">人员管理</span>
            </el-menu-item>
<!--            <el-menu-item index="4" @click="$router.push('/datesource')">-->
<!--              <i class="el-icon-setting"></i>-->
<!--              <span slot="title">数据源管理</span>-->
<!--            </el-menu-item>-->
<!--            <el-menu-item index="5" @click="$router.push('/flow')">-->
<!--              <i class="el-icon-s-cooperation"></i>-->
<!--              <span slot="title">采集列表</span>-->
<!--            </el-menu-item>-->
<!--            <el-menu-item index="6" @click="$router.push('/person')">-->
<!--              <i class="el-icon-setting"></i>-->
<!--              <span slot="title">个人中心</span>-->
<!--            </el-menu-item>-->
<!--            <el-menu-item index="7" @click="$router.push('/system')">-->
<!--              <i class="el-icon-setting"></i>-->
<!--              <span slot="title">系统设置</span>-->
<!--            </el-menu-item>-->
          </el-menu>
        </el-col>
      </el-row>

      </el-aside>
      <el-container>
        <el-main >

<!--          <div v-show="show_tuxing" ref="myChart" style="width: 400px; height: 400px; background-color: #ffffff; padding: 20px; border-radius: 20px;float: left"></div>-->



          <el-calendar style="width: 1000px;height: 650px;float: right;margin-right: 350px">
            <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <p :class="data.isSelected ? 'is-selected' : ''">
                {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️' : ''}}
              </p>
            </template>
          </el-calendar>

        </el-main>



        <!-- <el-footer>底部内容部分</el-footer> -->
      </el-container>
    </el-container>
  </el-container>


  <!--重置密码-->
  <el-dialog title="修改密码" :visible.sync="xiugaimima"  width="30%">
    <el-form :model="ruleForm_password" :rules="rules_password" ref="ruleForm_password">
      <el-form-item label="原密码"  prop="yuan_password">
        <el-input style="width: 300px;" v-model="ruleForm_password.yuan_password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="xin_password" >
        <el-input style="width: 300px;" v-model="ruleForm_password.xin_password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="chack_password" >
        <el-input style="width: 300px;" v-model="ruleForm_password.chack_password" autocomplete="off"></el-input>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm_password('ruleForm_password'),xiugaimima = false">取 消</el-button>
      <el-button type="primary" @click="queren_password('ruleForm_password')">确 定</el-button>
    </div>
  </el-dialog>


</div>

</template>

<script>
// !function(e, t, a) {
//   function r() {
//     for (var e = 0; e < s.length; e++) s[e].alpha <= 0 ? (t.body.removeChild(s[e].el), s.splice(e, 1)) : (s[e].y--, s[e].scale += .004, s[e].alpha -= .013, s[e].el.style.cssText = "left:" + s[e].x + "px;top:" + s[e].y + "px;opacity:" + s[e].alpha + ";transform:scale(" + s[e].scale + "," + s[e].scale + ") rotate(45deg);background:" + s[e].color + ";z-index:99999");
//     requestAnimationFrame(r)
//   }
//   function n() {
//     var t = "function" == typeof e.onclick && e.onclick;
//     e.onclick = function(e) {
//       t && t(),
//           o(e)
//     }
//   }
//   function o(e) {
//     var a = t.createElement("div");
//     a.className = "heart",
//         s.push({
//           el: a,
//           x: e.clientX - 5,
//           y: e.clientY - 5,
//           scale: 1,
//           alpha: 1,
//           color: c()
//         }),
//         t.body.appendChild(a)
//   }
//   function i(e) {
//     var a = t.createElement("style");
//     a.type = "text/css";
//     try {
//       a.appendChild(t.createTextNode(e))
//     } catch(t) {
//       a.styleSheet.cssText = e
//     }
//     t.getElementsByTagName("head")[0].appendChild(a)
//   }
//   function c() {
//     return "rgb(" + ~~ (255 * Math.random()) + "," + ~~ (255 * Math.random()) + "," + ~~ (255 * Math.random()) + ")"
//   }
//   var s = [];
//   e.requestAnimationFrame = e.requestAnimationFrame || e.webkitRequestAnimationFrame || e.mozRequestAnimationFrame || e.oRequestAnimationFrame || e.msRequestAnimationFrame ||
//       function(e) {
//         setTimeout(e, 1e3 / 60)
//       },
//       i(".heart{width: 10px;height: 10px;position: fixed;background: #f00;transform: rotate(45deg);-webkit-transform: rotate(45deg);-moz-transform: rotate(45deg);}.heart:after,.heart:before{content: '';width: inherit;height: inherit;background: inherit;border-radius: 50%;-webkit-border-radius: 50%;-moz-border-radius: 50%;position: fixed;}.heart:after{top: -5px;}.heart:before{left: -5px;}"),
//       n(),
//       r()
// } (window, document);






import request from "../utils/request";
import router from "@/router";
import * as echarts from 'echarts';

export default {
  mounted() {

  },

  created() {

    // this.chaxun_yizhou();
    // this.chaxun_yizhou_1();
    // this.chaxun_yizhou_2();
    // this.chaxun_yizhou_3();
    //拦截没有登录的用户开始
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token){
      router.push('/')
      this.$message.error("您还没有登录，请登录！")
    }
    //request.get('/user/all/')//这个的作用是为了拦截没有登录的情况下不可访问



  },

  watch: {
    // // 监听 zongshu 的变化
    // zongshu(newVal) {
    //   this.updateChart();
    // },
    // zongshu_1(newVal) {
    //   this.updateChart();
    // },
    // zongshu_2(newVal) {
    //   this.updateChart();
    // },
    // zongshu_3(newVal) {
    //   this.updateChart();
    // }
  },

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm_password.chack_password !== '') {
          this.$refs.ruleForm_password.validateField('chack_password');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm_password.xin_password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      xiugaimima:false,//修改密码
      ruleForm_password:{
        yuan_password:'',
        xin_password:'',
        chack_password:''
      },
      from_password:{
        userId:'',
        newPassword:'',
        oldPassword:'',
      },
      rules_password:{
        yuan_password:[
          { required: true, message: '请输入原密码', trigger: 'blur' },
        ],
        xin_password: [
          { required: true,  validator: validatePass, trigger: 'blur' }
        ],
        chack_password: [
          {required: true,  validator: validatePass2, trigger: 'blur' }
        ],
      },


      token:JSON.parse(localStorage.getItem('token')),
      loginId:JSON.parse(localStorage.getItem('loginId')),
      name:JSON.parse(localStorage.getItem('name')),

      show_tuxing : false,

      value: new Date(),
      formdingdan:{
        soNo:'',
        enterpriseName:'',
        goodsName:'',
        soManufacturer:'',
        soPrice:'',

        soProductTimeStart:'',//生产日期开始结束
        soProductTimeEnd:'',//生产日期开始结束

        soEffectiveTimeStart:'',//有效期开始
        soEffectiveTimeEnd:'',//有效期结束

        soTimeStart:'',////订单时间开始
        soTimeEnd:'',//订单时间结束
      },


      value_1: this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 7))),
      value_2: this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 1))),

      zongshu:'',
      zongshu_1:'',
      zongshu_2:'',
      zongshu_3:'',
    }
  },
  methods: {
    formatDateTime(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    updateChart(){
      // 初始化 ECharts 实例，使用 this.$refs.myChart 获取模板中定义的 DOM 元素
      this.myChart = echarts.init(this.$refs.myChart);

      // 设置图表的配置项
      this.myChart.setOption({
        title: {
          text: this.zongshu, // 主标题文本
          subtext: '前一周的销售数量', // 副标题文本
          left: 'center', // 标题位置，居中
          top: '43%', // 标题位置，距离顶部 43%
          subtextStyle: {
            fontSize: 18 // 副标题字体大小
          }
        },
        tooltip: {
          trigger: 'item' // 提示框触发方式，触发方式包括：item（数据项图形触发）、axis（坐标轴触发）
        },
        legend: {
          icon: 'circle', // 图例形状，圆形
          top: '0', // 图例位置，距离顶部 0
          left: 'right' // 图例位置，靠右
        },
        series: [
          {
            name: '销售数量', // 系列名称
            type: 'pie', // 图表类型，饼图
            radius: ['40%', '55%'], // 饼图半径，第一个值内半径，第二个值外半径
            label: {
              show: true, // 是否显示标签
              padding: [0, -60], // 标签内边距，第一个值上和左内边距，第二个值下和右内边距
              overflow: 'none', // 标签内容超出时处理方式，这里是不处理
              fontSize: '15', // 标签字体大小
              fontWeight: 'bold', // 标签字体粗细，加粗
              formatter: '{d}%\n\n{c}' // 标签内容格式器，{d}% 表示百分比，{c} 表示数据值
            },
            labelLine: {
              show: true, // 是否显示标签引导线
              length: 25, // 标签引导线第一段的长度
              length2: 60 // 标签引导线第二段的长度
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  // 定义颜色数组
                  var colorList = ['#4FC3F7', '#00C853', '#F57F17'];//, '#d472df', '#80c5da', '#a2ecd3', '#e1dc75'
                  // 根据数据项索引返回颜色
                  return colorList[params.dataIndex];
                }
              }
            },
            data: [
              { name: '前两天', value: this.zongshu_1 },
              { name: '前三天', value: this.zongshu_2 },
              { name: '前四天总和', value: this.zongshu_3 },
              // { name: '星期四', value: 100},
              // { name: '星期五', value: 200},
              // { name: '星期六', value: 300},
              // { name: '星期天', value: 400},
            ]
          }
        ]
      });
    },


    dianji_password(){
      this.xiugaimima = true;
      this.from_password = {};
      this.ruleForm_password = {};
    },
    //修改密码的弹窗——取消
    resetForm_password(formName) {
      this.$refs[formName].resetFields();
    },
    queren_password(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.from_password.userId = this.loginId
          this.from_password.oldPassword = this.ruleForm_password.yuan_password
          this.from_password.newPassword = this.ruleForm_password.xin_password
          request.post('/users/updatepassword',this.from_password).then(res=>{
            if (res.code === 200 ){
              this.$message.success(res.data)
            } else if(res.code === 501){
              this.$message.error(res.message)
            }
            else {
              this.$message.error("修改失败")
            }
          })
          this.xiugaimima = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },


    logout() {
      // 执行退出逻辑...
      // 删除 'user_token' cookie
      localStorage.removeItem('token');
      localStorage.removeItem('name');

      // 重定向到登录页面或执行其他操作
      this.$router.push('/');
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    //查询最近一周的订单情况
    chaxun_yizhou(){
      this.formdingdan.soTimeStart = this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 7)));
      this.formdingdan.soTimeEnd = this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 1)));
      request.post('/subfhd/condition',  this.formdingdan,{
        params: {
          pageNum: 1,
          pageSize: 10
        },
        timeout: 30000// 设置超时时间为10秒（10000毫秒）
      }).then(res => {
        if ( res.code === 200){
          this.zongshu = res.data.total
          this.show_tuxing = true
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      })
    },

    chaxun_yizhou_1(){
      this.formdingdan.soTimeStart = this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 3)));
      this.formdingdan.soTimeEnd = this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 2)));
      request.post('/subfhd/condition',  this.formdingdan,{
        params: {
          pageNum: 1,
          pageSize: 10
        },
        timeout: 30000// 设置超时时间为10秒（10000毫秒）
      }).then(res => {
        if ( res.code === 200){
          this.zongshu_1 = res.data.total
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      })
    },
    chaxun_yizhou_2(){
      this.formdingdan.soTimeStart = this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 4)));
      this.formdingdan.soTimeEnd = this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 3)));
      request.post('/subfhd/condition',  this.formdingdan,{
        params: {
          pageNum: 1,
          pageSize: 10
        },
        timeout: 30000// 设置超时时间为10秒（10000毫秒）
      }).then(res => {
        if ( res.code === 200){
          this.zongshu_2 = res.data.total
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      })
    },
    chaxun_yizhou_3(){
      this.formdingdan.soTimeStart = this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 7)));
      this.formdingdan.soTimeEnd = this.formatDateTime(new Date(new Date().setDate(new Date().getDate() - 4)));
      request.post('/subfhd/condition',  this.formdingdan,{
        params: {
          pageNum: 1,
          pageSize: 10
        },
        timeout: 30000// 设置超时时间为10秒（10000毫秒）
      }).then(res => {

        if ( res.code === 200){
          this.zongshu_3 = res.data.total
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          }
        }

      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      })
    },








  }
}
</script>
<style>
.is-selected {
  color: #1989FA;
}

.el-header, .el-footer {
  background-color: #3b87d3;
  color: #333;
  text-align: center;
  line-height: 100%;
}

.el-aside {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 100%;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 100%;
}

.title-text{
  color: white;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

</style>