<template>

  <div>
    <div class="shell">

      <div id="img-box" style="margin-top: 50px;transform: scale(1.1);">
        <img src="../img/bgimg1.png" alt="">
      </div>

      <el-form  :model="loginFormadd" status-icon  ref="loginFormadd" label-width="100px" style="margin-top: 50px;transform: scale(1.1);">
        <div id="form-body">
          <div id="welcome-lines" style="margin-top: -40px; margin-right: -50px;">
            <!-- <div id="w-line-2">你好! </div> -->
            <div id="w-line-2">你好!  请注册新账号</div>
            <br>
            <br>
          </div>
          <div id="input-area" style="margin-right: 50px; transform: scale(1.5); ">
            <el-form-item label="新账号" prop="usernameadd" >
              <el-input placeholder="请输入账号" style="width: 200px " type="text" v-model="loginFormadd.usernameadd"
                        size="small"></el-input>
            </el-form-item>
            <el-form-item   label="新密码" prop="password" style="margin-top: 12px">
              <el-input  type="password"  placeholder="请输入新密码" style="width: 200px"    v-model="loginFormadd.password" autocomplete="off"
                        size="small" @keyup.enter.native="confirm" show-password></el-input>
            </el-form-item>
            <el-form-item    label="确认密码" prop="chackpassword" style="margin-top: 12px">
              <el-input type="password" placeholder="请输入再次输入密码" style="width: 200px"   v-model="loginFormadd.chackpassword" autocomplete="off"
                        size="small" @keyup.enter.native="confirm" show-password></el-input>
            </el-form-item>
          </div>
          <div id="submit-button-cvr" style="margin-top: 40px;margin-left: -120%">
            <el-form-item>
              <el-button type="primary" class="submit-button"  @click="user_add()" style="width: 200px">注 册</el-button>
            </el-form-item>
          </div>
          <div id="forgot-pass" >
            <el-button type="text" @click="$router.push('/login')">返回登录</el-button>
          </div>

        </div>
      </el-form>

    </div>

  </div>







</template>
<script>
import request from "@/utils/request";
import * as Cookies from "vue";
import router from "@/router";


export default {
  name: "MedLogin",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.loginFormadd.chackpassword !== '') {
          this.$refs.loginFormadd.validateField('chackpassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.loginFormadd.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };


    return {
      token:'',
      confirm_disabled: false,
      loginFormadd: {
        usernameadd: '',
        password: '',
        chackpassword:'',
      },
      add:{
        username:'',
        password:''
      },


      rules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
          {min: 1, max: 16, message: '用户名长度在 1 到 16 个字符', trigger: 'blur'}
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        chackpassword: [
          { validator: validatePass2, trigger: 'blur' }
        ],

      },

    }
  },

  methods: {
    router() {
      return router
    },
    user_add(){
      this.add.username = this.loginFormadd.usernameadd
      this.add.password = this.loginFormadd.password
      request.post('/user/register',this.add)
      request.post('/user/userLogin',this.add).then(res =>{
        if (res.code===200){
          localStorage.setItem('token', JSON.stringify(res.data.tokenValue ));//本地存储存入
          localStorage.setItem('name', JSON.stringify(this.add.username));//本地存储存入
          this.$router.push('/home');//路由跳转
          this.$message.success('注册成功！   欢迎'+this.add.username+"登录系统！");

        }else {
          this.$message.error('账号密码错误，请重新输入！');
        }
      })

    },

  }
}
</script>

<style scoped >
* {
  padding: 0;
  margin: 0;
  outline: none;
}
body {
  background: linear-gradient(45deg, #1709e2, #2370ff);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.shell{
  background: linear-gradient(45deg, #5985ff, #2f60ff);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}
form {
  position: relative;
}

.shell {
  display: flex;
  justify-content: center;
}

form {
  width: 560px;
  height: 520px;
  background-color: #fff;
  box-shadow: 0px 15px 40px #81a2d3;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#img-box {
  width: 330px;
  height: 520px;
}

#img-box img {
  height: 100%;
}

#form-body {
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#welcome-lines {
  width: 100%;
  text-align: center;
  line-height: 1;
}

#w-line-1 {
  color: #7f7f7f;
  font-size: 65px;
}

#w-line-2 {
  color: #9c9c9c;
  font-size: 30px;
  margin-top: 17px;
}

#input-area {
  width: 100%;
  margin-top: 40px;
}



.f-inp {
  padding: 13px 25px;
  border: 2px solid #6e6d6d;
  line-height: 1;
  border-radius: 20px;
  margin-bottom: 15px;
}

.f-inp input {
  width: 100%;
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: 0;
}

.f-inp input::placeholder {
  color: #b9b9b9;
}

#submit-button-cvr {
  margin-top: 20px;
}

.submit-button {
  display: block;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-left: 350px;
  padding: 14px 40px;
  border: 0;
  background-color: #81a2d3;
  border-radius: 25px;
  line-height: 1;
  cursor: pointer;
}

#forgot-pass {
  text-align: center;
  margin-top: 10px;
  margin-left: 60px;
}

#forgot-pass a {
  color: #868686;
  font-size: 12px;
  text-decoration: none;
  display: inline-block;
  margin-right: 20px; /*字之间空距离 */
}


</style>