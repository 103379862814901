

<template>
  <div>
    <el-container>
      <el-header>
      <h3 class="title-text">医药信息流向查询系统</h3>
      <el-dropdown style="float: right;margin-top: -30px">
              <span class="el-dropdown-link" style="color: white;">
                {{this.name}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu >
                <el-dropdown-item ><div @click="dianji_password">修改密码</div></el-dropdown-item>
                <el-dropdown-item ><div @click="logout()">退出</div></el-dropdown-item>
              </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-container>
        <el-aside width="200px" style="min-height: 880px">
          <el-row>
            <el-col :span="24">
              <el-menu
                  default-active="2"
                  class="el-menu-vertical-demo">
                <el-menu-item index="1" @click="$router.push('/home')">
                  <i class="el-icon-s-home"></i>
                  <span slot="title" >首页</span>
                </el-menu-item>
                <el-menu-item index="8" @click="$router.push('/xinxi')">
                  <i class="el-icon-menu"></i>
                  <span slot="title">药品管理</span>
                </el-menu-item>
                <el-menu-item index="2" @click="$router.push('/gather')">
                  <i class="el-icon-s-data"></i>
                  <span slot="title">流向查询</span>
                </el-menu-item>
                <el-menu-item index="3" @click="$router.push('/Salesman')">
                  <i class="el-icon-s-custom"></i>
                  <span slot="title">人员管理</span>
                </el-menu-item>
<!--                <el-menu-item index="4" @click="$router.push('/datesource')">-->
<!--                  <i class="el-icon-setting"></i>-->
<!--                  <span slot="title">数据源管理</span>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="5" @click="$router.push('/flow')">-->
<!--                  <i class="el-icon-s-cooperation"></i>-->
<!--                  <span slot="title">采集列表</span>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="6" @click="$router.push('/person')">-->
<!--                  <i class="el-icon-setting"></i>-->
<!--                  <span slot="title">个人中心</span>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="7" @click="$router.push('/system')">-->
<!--                  <i class="el-icon-setting"></i>-->
<!--                  <span slot="title">系统设置</span>-->
<!--                </el-menu-item>-->
              </el-menu>
            </el-col>
          </el-row>

        </el-aside>
        <el-container>
<!--          中间内容填充开始-->
          <el-main>
            <el-tabs type="border-card" v-model="activeTab"   @tab-click="handleTabClick"  >
              <el-tab-pane label="入库查询" name="ruku" >
                <div>
                  <el-button style="float: left" type="primary" icon="el-icon-download" @click="ruku_download" circle></el-button>
                  <div style="float: right">
                     <span style="float: right;width: 50px;margin-right: 100px">
                      <el-button @click="ruku_chongzhi" type="primary" icon="el-icon-refresh">重置</el-button>
                      </span>
                    <span style="float: right;width: 50px;margin-right: 50px">
                      <el-button @click="inbound_all()" type="primary" icon="el-icon-search">搜索</el-button>
                      </span>
                    <span style="float: right;width: 200px;margin-right: 10px">
                      <el-input v-model="inbound_from.goodsName" placeholder="请输入商品名称"></el-input>
                      </span>
<!--                    <div >-->
<!--                      <el-date-picker-->
<!--                          v-model="value2"-->
<!--                          style="float: left;margin-left: 10px"-->
<!--                          value-format="yyyy-MM-dd HH:mm:ss"-->
<!--                          type="datetimerange"-->
<!--                          :picker-options="pickerOptions"-->
<!--                          range-separator="至"-->
<!--                          start-placeholder="订单开始日期"-->
<!--                          end-placeholder="订单结束日期"-->
<!--                          align="right">-->
<!--                      </el-date-picker>-->
<!--                    </div>-->
                  </div>
                  <el-table
                      :data="tableData_inbound"
                      border
                      max-height="700px"
                      style="width: 100%"
                      show-summary
                      :summary-method="getSummaries_ruku"
                      v-loading="loading">
                    <el-table-column fixed   type="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column fixed  prop="goodsName" label="商品名称" width="180">
                    </el-table-column>
<!--                    <el-table-column prop="soId" label="编号" width="120">-->
<!--                    </el-table-column>-->
                    <el-table-column prop="poNo" label="订单号" width="200">
                    </el-table-column>
                    <el-table-column    type="date" prop="poTime"   sortable label="入库时间" width="200">
                    </el-table-column>
                    <el-table-column prop="enterpriseInnerCode" label="客户内码" width="80">
                    </el-table-column>
                    <el-table-column prop="enterpriseName" label="客户名称" width="370">
                    </el-table-column>
                    <el-table-column prop="poBatchNo" label="批号" width="120">
                    </el-table-column>
                    <el-table-column prop="poQuantity" label="入库数量" width="80">
                    </el-table-column>
                    <el-table-column prop="poProductTime" label="生产日期" width="100">
                    </el-table-column>
                    <el-table-column prop="poEffectiveTime" label="有效期" width="100">
                    </el-table-column>
                    <el-table-column prop="poPrice" label="价格" width="100">
                    </el-table-column>
                    <el-table-column prop="goodsInSn" label="商品内码" width="100">
                    </el-table-column>
                    <el-table-column prop="poLicense" label="批准文号" width="280">
                    </el-table-column>
                    <el-table-column prop="poSpecifications" label="商品规格" width="150">
                    </el-table-column>
                    <el-table-column prop="poUnit" label="单位" width="100">
                    </el-table-column>
                    <el-table-column prop="poManufacturer" label="商品生产厂家" width="370">
                    </el-table-column>
                    <el-table-column prop="tm" label="TM码" width="180">
                    </el-table-column>
                  </el-table>
<!--                  分页开始-->
                  <el-pagination
                      style="float: right;margin-right: 100px"
                      @size-change="handleSizeChange_inbound"
                      @current-change="handleCurrentChange_inbound"
                      :current-page="pagefenye_in.currentchange"
                      :page-sizes="[10,20,50]"
                      :page-size="pagefenye_in.pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="pagefenye_in.total">
                  </el-pagination>
<!--                  分页结束-->
                </div>
              </el-tab-pane>
              <el-tab-pane label="出库查询" name="chuku">
                <div>
                  <el-button style="float: left" type="primary" icon="el-icon-download" @click="chuku_download" circle></el-button>
                  <div style="float: right">
                    <span style="float: right;width: 50px;margin-right: 100px">
                      <el-button @click="chuku_chongzhi" type="primary" icon="el-icon-refresh">重置</el-button>
                      </span>
                    <span style="float: right;width: 50px;margin-right: 50px">
                      <el-button @click="outbound()" type="primary" icon="el-icon-search">搜索</el-button>
                    </span>
                    <span style="float: right;width: 200px">
                      <el-input v-model="input.goodsName" placeholder="请输入商品名称"></el-input>
                    </span>
                  </div>
                  <el-table
                      :data="tableData_outbound"
                      border
                      show-summary
                      :summary-method="getSummaries_chuku"
                      max-height="650px"
                      style="width: 100%"
                      v-loading="loading">
                    <el-table-column fixed   type="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column fixed  prop="goodsName" label="商品名称" width="180">
                    </el-table-column>
                    <el-table-column prop="soId" label="编号" width="120">
                    </el-table-column>
                    <el-table-column prop="lsh" label="出库单号" width="250">
                    </el-table-column>
                    <el-table-column    type="date"  prop="soTime"   sortable label="订单时间" width="200">
                    </el-table-column>
                    <el-table-column prop="enterpriseInnerCode" label="客户内码" width="80">
                    </el-table-column>
                    <el-table-column prop="enterpriseName" label="客户名称" width="370">
                    </el-table-column>
                    <el-table-column prop="soBatchNo" label="批号" width="150">
                    </el-table-column>
                    <el-table-column prop="soQuantity" label="出库数量" width="100">
                    </el-table-column>
                    <el-table-column prop="soProductTime" label="生产日期" width="100">
                    </el-table-column>
                    <el-table-column prop="soEffectiveTime" label="有效期" width="100">
                    </el-table-column>
                    <el-table-column prop="soPrice" label="价格" width="100">
                    </el-table-column>
                    <el-table-column prop="goodsInSn" label="商品内码" width="100">
                    </el-table-column>
                    <el-table-column prop="soLicense" label="批准文号" width="280">
                    </el-table-column>
                    <el-table-column prop="soSpecifications" label="商品规格" width="250">
                    </el-table-column>
                    <el-table-column prop="soUnit" label="单位" width="100">
                    </el-table-column>
                    <el-table-column prop="soManufacturer" label="商品生产厂家" width="370">
                    </el-table-column>
                    <el-table-column prop="soSource" label="其他销售渠道" width="180">
                    </el-table-column>
                    <el-table-column prop="tm" label="TM码" width="180">
                    </el-table-column>
                  </el-table>
                  <!--                  分页开始-->
                  <el-pagination
                      style="float: right;margin-right: 100px"
                      @size-change="handleSizeChange_outbound"
                      @current-change="handleCurrentChange_outbound"
                      :current-page="pagefenye_out.currentchange"
                      :page-sizes="[10,20,50]"
                      :page-size="pagefenye_out.pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="pagefenye_out.total">
                  </el-pagination>
                  <!--                  分页结束-->


                </div>
              </el-tab-pane >
              <el-tab-pane label="销售查询" name="yaopin" >
                <el-button style="float: left" type="primary" icon="el-icon-download" @click="download" circle></el-button>
                <span style="float: right;width: 50px;margin-right: 100px">
                      <el-button style="margin-right: 10px" @click="xiaoshou_chongzhi" type="primary" icon="el-icon-search">重置</el-button>
                </span>
                <span style="float: right;width: 50px;margin-right: 50px">
                      <el-button @click="yaopin_load1() , chaxuanzongshu()" type="primary" icon="el-icon-search">搜索</el-button>
                </span>
                <span style="float: right;width: 200px;margin-right: 10px">
                      <el-input v-model="input_goodsName" @keyup.enter.native ="yaopin_mohu_zongji"  placeholder="请输入商品名称"></el-input>
                </span>
                <el-table
                    ref="ids"
                    :data="tableData_yaopin"
                    border
                    show-summary
                    :summary-method="getSummaries"
                    min-height="800px"
                    style="width: 100%"
                    v-loading="loading">
                  <el-table-column fixed   type="index" label="序号" width="50">
                  </el-table-column>
                  <el-table-column fixed  prop="goodsName" label="商品名称" width="180">
                  </el-table-column>
                  <el-table-column prop="soId" label="编号" width="120">
                  </el-table-column>
                  <el-table-column prop="soNo" label="订单号" width="200">
                  </el-table-column>
                  <el-table-column    type="date" prop="soTime"   sortable label="订单时间" width="120">
                  </el-table-column>
                  <el-table-column prop="enterpriseInnerCode" label="客户内码" width="80">
                  </el-table-column>
                  <el-table-column prop="enterpriseName" label="客户名称" width="370">
                  </el-table-column>
                  <el-table-column prop="soBatchNo" label="批号" width="100">
                  </el-table-column>
                  <el-table-column prop="soQuantity" label="销售数量" width="100">
                  </el-table-column>
                  <el-table-column prop="soProductTime" label="生产日期" width="100">
                  </el-table-column>
                  <el-table-column prop="soEffectiveTime" label="有效期" width="100">
                  </el-table-column>
                  <el-table-column prop="soPrice" label="价格" width="100">
                  </el-table-column>
                  <el-table-column prop="goodsInSn" label="商品内码" width="100">
                  </el-table-column>
                  <el-table-column prop="soLicense" label="批准文号" width="180">
                  </el-table-column>
                  <el-table-column prop="soSpecifications" label="商品规格" width="150">
                  </el-table-column>
                  <el-table-column prop="soUnit" label="单位" width="100">
                  </el-table-column>
                  <el-table-column prop="soManufacturer" label="商品生产厂家" width="370">
                  </el-table-column>
                  <el-table-column prop="soSource" label="其他销售渠道" width="180">
                  </el-table-column>
                </el-table>
                <!--                  分页开始-->
                <el-pagination
                    style="float: right;margin-right: 100px"
                    @size-change="handleSizeChange_yaopin"
                    @current-change="handleCurrentChange_yaopin"
                    :current-page="pagefenye_yaopin.currentchange"
                    :page-sizes="[10 , 20 , 50 ]"
                    :page-size="pagefenye_yaopin.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagefenye_yaopin.total">
                </el-pagination>
                <!--                  分页结束-->


              </el-tab-pane>
            </el-tabs>
          </el-main>
<!--          中间内容填充结束-->
          <!-- <el-footer>底部内容部分</el-footer> -->
        </el-container>
      </el-container>
    </el-container>
    <!--重置密码-->
    <el-dialog title="修改密码" :visible.sync="xiugaimima"  width="30%">
      <el-form :model="ruleForm_password" :rules="rules_password" ref="ruleForm_password">
        <el-form-item label="原密码"  prop="yuan_password">
          <el-input style="width: 300px;" v-model="ruleForm_password.yuan_password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="xin_password" >
          <el-input style="width: 300px;" v-model="ruleForm_password.xin_password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="chack_password" >
          <el-input style="width: 300px;" v-model="ruleForm_password.chack_password" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm_password('ruleForm_password'),xiugaimima = false">取 消</el-button>
        <el-button type="primary" @click="queren_password('ruleForm_password')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import router from "@/router";
//引入js文件(js文件代码在该部分代码之后)
import { debounce, throttle} from "@/utils/statice";

const time_sum =  1 ;

export default {

  time_sum, // 记录时间

  created() {
    //拦截没有登录的用户开始
    const token = JSON.parse(localStorage.getItem('token'))
    if (!token){
      router.push('/')
      this.$message.error("您还没有登录，请登录！")
    }
    else {
      if (this.activeTab === 'ruku') {
        this.inbound_all();
        this.chaxuanzongshu_ruku();
      }
    }
    //request.get('/user/all/')//这个的作用是为了拦截没有登录的情况下不可访问
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm_password.chack_password !== '') {
          this.$refs.ruleForm_password.validateField('chack_password');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm_password.xin_password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      xiugaimima:false,//修改密码
      ruleForm_password:{
        yuan_password:'',
        xin_password:'',
        chack_password:''
      },
      from_password:{
        userId:'',
        newPassword:'',
        oldPassword:'',
      },
      rules_password:{
        yuan_password:[
          { required: true, message: '请输入原密码', trigger: 'blur' },
        ],
        xin_password: [
          { required: true,  validator: validatePass, trigger: 'blur' }
        ],
        chack_password: [
          {required: true,  validator: validatePass2, trigger: 'blur' }
        ],
      },
      loading: false, // 加载状态变量

      zongshu_heji:'',//  销售合计
      zongshu_heji_ruku:'',//  入库合计
      zongshu_heji_chuku:'',//  出库合计

      // 分页开始
      pagefenye:{
        total:'',
        pagesize:'',
        currentchange:''
      },
      // 分页结束
      // 分页开始
      pagefenye_out:{
        total:'',
        pagesize:'',
        currentchange:''
      },
      // 分页结束
      // 分页开始
      pagefenye_in:{
        total:'',
        pagesize:'',
        currentchange:''
      },
      // 分页结束
      // 分页开始
      pagefenye_yaopin:{
        total:'',
        pagesize:'',
        currentchange:''
      },
      // 分页结束

      token:JSON.parse(localStorage.getItem('token')),
      loginId:JSON.parse(localStorage.getItem('loginId')),
      name:JSON.parse(localStorage.getItem('name')),
      tableData_gycs:[],//供应厂商页面查询列表数据
      tableData_yaopin:[],//药品查询页面的表格数据
      yaopin_from:{
        userId:''
      },
      input_goodsName:'',
      ruku_from:{
        userId:''
      },

      activeTab: 'ruku', // 设置默认激活的标签页

      subfhd_from:{
        soNo:'',
        enterpriseName:'',
        goodsName:'',
        soManufacturer:'',
        soPrice:'',
        soProductTimeStart:'',//生产日期开始结束
        soProductTimeEnd:'',//生产日期开始结束
        soEffectiveTimeStart:'',//有效期开始
        soEffectiveTimeEnd:'',//有效期结束
        soTimeStart:'',////订单时间开始
        soTimeEnd:'',//订单时间结束
      },
      inbound_from:{
        goodsName:'',
      },
      input:{
        goodsName:'',

        // outbound:'',
        // inbound:'',
        // medicine_inbound:'',//入库表的药品
        // batchNumber_inbound:'',//入库表的批号
        // medicine:'',//出库表的药品
        // batchNumber:'',//出库表的批号
        // quantity:'',//出库表的数量

        // sales:'',//销售表的销售订单号
        // salesPerson:'',//销售表的销售员
        // salesMerchant:''//销售表的销售商家
      },
      tableData: [],
      tableData_inbound:[],//入库
      tableData_sales:[],//销售
      tableData_outbound:[],//出库

      ids:[],//为多选列表的id值的列表



      // 日期选择器的便捷开始
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value2: '',
      value3:'',
      value4:'',
      // sum:JSON.parse(localStorage.getItem('sum')),

      // 日期选择器的便捷结束

    }
  },
  // watch: {//该方法跟下面的mounted方法一样，但是如果单用这个方法的话在该页面进行刷新时执行不了
  //   // 监听activeTab的变化
  //   activeTab(newTab, oldTab) {
  //     if (newTab === 'sales') {
  //       this.sales();
  //     }
  //     // 如果有其他标签页也需要自动执行方法，可以在这里添加判断
  //   },
  // },
  mounted() {
    // 当组件挂载后，如果默认标签是'sales'，也会自动执行方法
    // if (this.activeTab === 'sales') {
    //   this.sales();
    // }
    //     let timer = null;
    //     //每5s刷新数据
    //     timer = setInterval(() => {
    //       this.sum = this.sum + 1
    //       localStorage.setItem('sum', JSON.stringify(this.sum));//本地存储存入
    //      console.log(this.sum)
    //
    //     }, 1000);
  },

  // watch: {
  //   input_goodsName: {
  //     handler(newVal,oldVal) {
  //       this.yaopin_load1();
  //       this.chaxuanzongshu();
  //     },
  //     deep:true
  //   },
  //   input:{
  //     handler(newVal,oldVal) {
  //       this.outbound();
  //       this.chaxuanzongshu_chuku();
  //     },
  //
  //     deep:true
  //   },
  //   inbound_from:{
  //     handler(newVal,oldVal){
  //       this.inbound_all();
  //       this.chaxuanzongshu_ruku();
  //     },
  //
  //     deep:true
  //   }
  // },

  methods: {
    dianji_password(){
      this.xiugaimima = true;
      this.from_password = {};
      this.ruleForm_password = {};
    },
    //修改密码的弹窗——取消
    resetForm_password(formName) {
      this.$refs[formName].resetFields();
    },
    queren_password(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.from_password.userId = this.loginId
          this.from_password.oldPassword = this.ruleForm_password.yuan_password
          this.from_password.newPassword = this.ruleForm_password.xin_password
          request.post('/users/updatepassword',this.from_password).then(res=>{
            if (res.code === 200 ){
              this.$message.success(res.data)
            } else if(res.code === 501){
              this.$message.error(res.message)
            }
            else {
              this.$message.error("修改失败")
            }
          })
          this.xiugaimima = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },



    // 分页方法
    //入库
    handleSizeChange_inbound(newSize) {
      this.pagefenye_in.pagesize = newSize;
      this.inbound_all(); // 重新获取数据
    },
    handleCurrentChange_inbound(newPage) {
      this.pagefenye_in.currentchange = newPage;
      this.inbound_all(); // 重新获取数据
    },
    //分页结束

    // 分页方法
    //销售
    handleSizeChange_yaopin(newSize) {
      this.pagefenye_yaopin.pagesize = newSize;
      this.yaopin_load(); // 重新获取数据
    },
    handleCurrentChange_yaopin(newPage) {
      this.pagefenye_yaopin.currentchange = newPage;
      this.yaopin_load(); // 重新获取数据
    },
    //分页结束

    //分页结束
    // 分页方法
    //出库
    handleSizeChange_outbound(newSize) {
      this.pagefenye_out.pagesize = newSize;
      this.outbound(); // 重新获取数据
    },
    handleCurrentChange_outbound(newPage) {
      this.pagefenye_out.currentchange = newPage;
      this.outbound(); // 重新获取数据
    },
    //分页结束

    //防抖
    antiShake: debounce(function (...args) {
      this.submitForm(...args) //这里是要防的方法
    }, 2000, true),
    //节流
    throttling: throttle(function (...args) {
      this.inbound_all(...args)
      // this.chaxuanzongshu_ruku(...args);
    }, 5000),
    throttlchuku: throttle(function (...args) {
      this.outbound(...args)
      // this.chaxuanzongshu_chuku(...args);
    }, 5000),
    throttlyaopin: throttle(function (...args) {
      this.yaopin_load(...args)
      // this.chaxuanzongshu(...args);
    }, 5000),

    handleTabClick () {
      // 此处可以执行一些基于 tab.name 的逻辑
      // 比如当点击 '入库查询' 标签页时，可能不需要执行任何操作
      // 因为 activeTab 已经自动更新了
      if (this.activeTab === 'ruku'){
        this.throttling();
      }
      if (this.activeTab === 'chuku')
      {
        this.throttlchuku();
      }
      if (this.activeTab === 'yaopin')
      {
        this.throttlyaopin();

      }
    },

    logout() {
      // 执行退出逻辑...
      // 删除 'user_token' cookie
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      // 可选：如果你也想删除所有 cookies
      // this.$cookies.keys().forEach(key => this.$cookies.remove(key));

      // 重定向到登录页面或执行其他操作
      this.$router.push('/');
    },

    //销售药品
    yaopin_load(){
      this.loading = true; // 在请求开始前设置loading为true
      this.yaopin_from.userId = this.loginId
      request.post('/subfhd/condition2', this.yaopin_from,{
        params:{
          pageNum: this.pagefenye_yaopin.currentchange,//这里是动态获取前端的
          pageSize: this.pagefenye_yaopin.pagesize,
          goodsName: this.input_goodsName
        }
      }).then(res=>{
        if ( res.code === 200){
          this.tableData_yaopin = res.data.records
          this.pagefenye_yaopin.total = res.data.total
          this.pagefenye_yaopin.pagesize = res.data.size
          this.pagefenye_yaopin.currentchange = res.data.current
        }
        if( res.code === 500){
          if(res.message){
            this.$message.error('缺少查看权限!!！');
          }
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      }).finally(() => {
        this.loading = false; // 在请求完成后设置loading为false;
      });
    },



    //入库的搜索重置
    ruku_chongzhi(){
      this.pagefenye_in.currentchange = 1
      this.pagefenye_in.pagesize = 10
      this.inbound_from.goodsName = ''
      this.inbound_all();
    },
    //入库的查询
    inbound_all(){
      this.loading = true; // 在请求开始前设置loading为true
      this.ruku_from.userId = this.loginId;
      request.post('/goodsrk/condition2',this.ruku_from , {
        params: {
          pageNum: this.pagefenye_in.currentchange,//这里是动态获取前端的
          pageSize: this.pagefenye_in.pagesize,
          goodsName: this.inbound_from.goodsName
        },
        timeout: 10000// 设置超时时间为10秒（10000毫秒）
      }).then(res => {
        if ( res.code === 200){
          this.tableData_inbound = res.data.records; // 通常我们取 res.data 作为响应体
          this.pagefenye_in.total = res.data.total   //这里是作为时间的参数
          this.pagefenye_in.pagesize = res.data.size
          this.pagefenye_in.currentchange = res.data.current
        }
        if( res.code === 500){
          if(res.message){
            this.$message.error('缺少查看权限!!！');
          }
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }

        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      }).finally(() => {
        this.loading = false; // 在请求完成后设置loading为false;
      });
    },

    //出库的搜索重置
    chuku_chongzhi(){
      this.input.goodsName = ''
      this.pagefenye_out.currentchange = 1
     this.pagefenye_out.pagesize = 10
      this.outbound();
    },
    // 出库信息查询
    outbound () {
      this.loading = true; // 在请求开始前设置loading为true
      this.ruku_from.userId = this.loginId;
      request.post('/goodsck/condition2',this.ruku_from , {
        params: {
          pageNum: this.pagefenye_out.currentchange,//这里是动态获取前端的
          pageSize: this.pagefenye_out.pagesize,
          goodsName: this.input.goodsName
        },
        timeout: 10000// 设置超时时间为10秒（10000毫秒）
      }).then(res => {
        if ( res.code === 200){
          this.tableData_outbound = res.data.records; // 通常我们取 res.data 作为响应体
          this.pagefenye_out.total = res.data.total
          this.pagefenye_out.pagesize = res.data.size
          this.pagefenye_out.currentchange = res.data.current
        }
        if( res.code === 500){
          if(res.message){
            this.$message.error('缺少查看权限!!！');
          }
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        // else {
        //   // 对于其他类型的错误，可以认为是没有权限或其他问题
        //   this.$message.error('您当前没有该权限！或者发生了其他错误！');
        // }
        console.error('Error fetching sales data:', error);
      }).finally(() => {
        this.loading = false; // 在请求完成后设置loading为false;
      });
    },

    //销售文件的下载
      async download() { // 这里最好采用异步处理
      this.loading = true
      try {
        const response = await request.get('/subfhd/exportExcel?userId='+this.loginId, {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//这里的数据类型跟后端的数据类型保持一致
          },
          timeout:10000,
          responseType: 'blob', // 指定响应类型为 Blob
        });
        let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        // 创建一个a标签用于触发下载
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute('download', '销售 数据导出.xlsx'); // 指定下载的文件名
        // 将a标签添加到文档中并触发点击事件
        document.body.appendChild(link);
        link.style.display = 'none'; // 隐藏链接
        link.click(); // 触发下载
        document.body.removeChild(link); // 下载后移除元素
        window.URL.revokeObjectURL(url); // 释放 URL 对象
        this.$message({
          type: 'success',
          message: '下载成功!'
        });
      } catch (error) {
        // 处理错误情况
        console.error('下载失败:', error);
        this.$message({
          type: 'error',
          message: '下载失败，请稍后再试'
        });
      } finally {
        // 隐藏加载状态
        this.loading = false;
      }
    },
    //入库文件的下载
    async ruku_download() { // 这里最好采用异步处理
      this.loading = true
      try {
        const response = await  request.get('/goodsrk/exportExcel?userId='+this.loginId, {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//这里的数据类型跟后端的数据类型保持一致
          },
          responseType: 'blob', // 指定响应类型为 Blob
        });
        let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        // 创建一个a标签用于触发下载
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute('download', '入库 数据导出.xlsx'); // 指定下载的文件名
        // 将a标签添加到文档中并触发点击事件
        document.body.appendChild(link);
        link.style.display = 'none'; // 隐藏链接
        link.click(); // 触发下载
        document.body.removeChild(link); // 下载后移除元素
        window.URL.revokeObjectURL(url); // 释放 URL 对象
        this.$message({
          type: 'success',
          message: '下载成功!'
        });
      } catch (error) {
        // 处理错误情况
        console.error('下载失败:', error);
        this.$message({
          type: 'error',
          message: '下载失败，请稍后再试'
        });
      } finally {
        // 隐藏加载状态
        this.loading = false;
      }
    },
    //出库文件的下载
      async chuku_download(){ // 这里最好采用异步处理
      this.loading = true
      try {
        const response =await  request.get('/goodsck/exportExcel?userId='+this.loginId, {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//这里的数据类型跟后端的数据类型保持一致
          },
          responseType: 'blob', // 指定响应类型为 Blob
        });
        let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        // 创建一个a标签用于触发下载
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute('download', '出库 数据导出.xlsx'); // 指定下载的文件名
        // 将a标签添加到文档中并触发点击事件
        document.body.appendChild(link);
        link.style.display = 'none'; // 隐藏链接
        link.click(); // 触发下载
        document.body.removeChild(link); // 下载后移除元素
        window.URL.revokeObjectURL(url); // 释放 URL 对象
        this.$message({
          type: 'success',
          message: '下载成功!'
        });
      } catch (error) {
        // 处理错误情况
        console.error('下载失败:', error);
        this.$message({
          type: 'error',
          message: '下载失败，请稍后再试'
        });
      } finally {
        // 隐藏加载状态
        this.loading = false;
      }
    },



    //数据采集按钮
    caiji(){
      request.get('/sql/add').then(()=>{
        this.$message.success('正在更新数据请稍等');
        this.chaxun_all();
      })
    },


    yaopin_mohu_zongji(){
      this.yaopin_load1();
      this.chaxuanzongshu();
    },

    xiaoshou_chongzhi(){
      this.input_goodsName = ''
      this.yaopin_load1();
    },

    yaopin_load1(){
      this.yaopin_from.userId = this.loginId
      request.post('/subfhd/condition2', this.yaopin_from,{
        params:{
          pageNum: 1,//这里是动态获取前端的
          pageSize: 10,
          goodsName: this.input_goodsName
        }
      }).then(res=>{
        if ( res.code === 200){
          this.tableData_yaopin = res.data.records
          this.pagefenye_yaopin.total = res.data.total
          this.pagefenye_yaopin.pagesize = res.data.size
          this.pagefenye_yaopin.currentchange = res.data.current
        }
        if( res.code === 500){
          if (res.msg[0] === 't'){
            this.$message.error('登录身份已过期，请重新登录!!！');
            this.$router.push('/');
          } else {
            this.$message.error('缺少查看权限!!！');
          }
        }
      }).catch(error => {
        if (error.code === 'ECONNABORTED') {
          // 如果错误代码是 ECONNABORTED，则表示请求超时
          this.$message.error('网络请求超时，请稍后再试！');
        }
        console.error('Error fetching sales data:', error);
      })
    },

    //这里是调用总计的接口
    chaxuanzongshu(){
      request.post('subfhd/condition3?userId='+ this.loginId+'&goodsName='+this.input_goodsName).then(res=>{
        this.zongshu_heji = res
      })
    },
    //这里是销售表格的总计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          if (column.property === 'soQuantity') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = ''; // 其他列不显示合计
          }
        } else {
          sums[index] = '';
        }
      });
      return [
        <span>
          <strong> 小计 </strong>
          <br/>
          <br/>
          <strong> 总计 </strong>
        </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span>
        <span> {sums} </span> <br/>
          <br/>
        <span> {this.zongshu_heji} </span>
        </span>

      ]
    },

    chaxuanzongshu_ruku(){
      request.post('goodsrk/condition3?userId='+ this.loginId+'&goodsName='+this.input_goodsName).then(res=>{
        this.zongshu_heji_ruku = res
      })
    },
    //这里是入库数量的统计
    getSummaries_ruku(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          if (column.property === 'poQuantity') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = ''; // 其他列不显示合计
          }
        } else {
          sums[index] = '';
        }
      });
      return [
        <span>
         <strong> 小计 </strong>
          <br/>
          <br/>
          <strong> 总计 </strong>
        </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span>
          <span> {sums} </span> <br/> <br/>
          <span> {this.zongshu_heji_ruku} </span>
        </span>

      ]
    },


    chaxuanzongshu_chuku(){
      request.post('goodsck/condition3?userId='+ this.loginId+'&goodsName='+this.input_goodsName).then(res=>{
        this.zongshu_heji_chuku = res
      })
    },
    //这里是出库数量的统计
    getSummaries_chuku(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          if (column.property === 'soQuantity') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = ''; // 其他列不显示合计
          }
        } else {
          sums[index] = '';
        }
      });
      return [
        <span>
         <strong> 小计 </strong>
          <br/>
          <br/>
          <strong> 总计 </strong>
        </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span> </span>,
        <span>
          <span> {sums} </span> <br/> <br/>
          <span> {this.zongshu_heji_chuku} </span>
        </span>

      ]
    }


  }
}
</script>

<style scoped>

</style>