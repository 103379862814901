
<template>

  <div>
    <el-container>
      <el-header>
      <h3 class="title-text">医药信息流向查询系统</h3>
      <el-dropdown style="float: right;margin-top: -30px">
              <span class="el-dropdown-link" style="color: white;">
                {{this.name}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item ><div @click="logout()">退出</div></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-container>
        <el-aside width="200px" style="min-height: 880px">
          <el-row>
            <el-col :span="24">
              <el-menu
                  default-active="6"
                  class="el-menu-vertical-demo"
                  @open="handleOpen"
                  @close="handleClose">
                <el-menu-item index="1" @click="$router.push('/home')">
                  <i class="el-icon-s-home"></i>
                  <span slot="title" >首页</span>
                </el-menu-item>
                <el-menu-item index="8" @click="$router.push('/xinxi')">
                  <i class="el-icon-menu"></i>
                  <span slot="title">药品管理</span>
                </el-menu-item>
                <el-menu-item index="2" @click="$router.push('/gather')">
                  <i class="el-icon-menu"></i>
                  <span slot="title">流向查询</span>
                </el-menu-item>
                <el-menu-item index="3" @click="$router.push('/Salesman')">
                  <i class="el-icon-s-custom"></i>
                  <span slot="title">业务员</span>
                </el-menu-item>
                <el-menu-item index="4" @click="$router.push('/datesource')">
                  <i class="el-icon-setting"></i>
                  <span slot="title">数据源管理</span>
                </el-menu-item>
                <el-menu-item index="5" @click="$router.push('/flow')">
                  <i class="el-icon-s-cooperation"></i>
                  <span slot="title">采集列表</span>
                </el-menu-item>
                <el-menu-item index="6" @click="$router.push('/person')">
                  <i class="el-icon-setting"></i>
                  <span slot="title">个人中心</span>
                </el-menu-item>
                <el-menu-item index="7" @click="$router.push('/system')">
                  <i class="el-icon-setting"></i>
                  <span slot="title">系统设置</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>

        </el-aside>
        <el-container>
<!--          中间内容填充开始-->
          <el-main>
            个人中心
          </el-main>
<!--          中间内容填充结束-->

          <!-- <el-footer>底部内容部分</el-footer> -->
        </el-container>
      </el-container>
    </el-container>

  </div>
</template>
<script>
import router from "@/router";

export default {
  created() {
    //拦截没有登录的用户开始
    const token = JSON.parse(localStorage.getItem('token'))
    if (!token){
      router.push('/')
      this.$message.error("您还没有登录，请登录！")
    }
    //request.get('/user/all/')//这个的作用是为了拦截没有登录的情况下不可访问
  },
  data(){
    return{
      name:JSON.parse(localStorage.getItem('name')),
    }
  },
  methods: {
    logout() {
      // 执行退出逻辑...
      // 删除 'user_token' cookie
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      // 可选：如果你也想删除所有 cookies
      // this.$cookies.keys().forEach(key => this.$cookies.remove(key));

      // 重定向到登录页面或执行其他操作
      this.$router.push('/');
    },
  }
}
</script>
<style scoped>

</style>