import { render, staticRenderFns } from "./SysTem.vue?vue&type=template&id=209e0e1e&scoped=true"
import script from "./SysTem.vue?vue&type=script&lang=js"
export * from "./SysTem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "209e0e1e",
  null
  
)

export default component.exports